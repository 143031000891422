<template>
  <v-dialog v-model="show" scrollable width="600" transition="dialog-bottom-transition">
    <v-card class="basicBackGround">
      <v-toolbar dark color="light-blue">
        <v-toolbar-title>Observações : {{ client.name }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon @click="toggleDialog()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Novo Registo</span>
        </v-tooltip>
      </v-toolbar>
      <v-card-text>

        <div v-if="loading" align="center" justify="center">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </div>
        <div v-else>
          <v-timeline clipped dense v-if="observations.length != 0">
            <v-timeline-item v-for="observation in observations" :key="observation.id" large>
              <template v-slot:icon>
                <v-avatar>
                  <img :src="baseUrl + '/' + observation.User.img">
                </v-avatar>
              </template>
              <v-card class="elevation-2">
                <v-card-title class="text-body-1">
                  {{ observation.createdAt.slice(0, 10) }}
                  <v-spacer></v-spacer>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon @click="toggleDialog(observation)">
                        <v-icon v-if="$store.state.user.role === 'admin' || observation.UserId === $store.state.user.id"
                          color="success">mdi-pencil</v-icon>
                        <v-icon v-else color="success">mdi-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ ($store.state.user.role === 'admin' || observation.UserId === $store.state.user.id) ? 'Editar' :
                      'Ver' }}</span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="$store.state.user.role === 'admin' || observation.UserId === $store.state.user.id">
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon @click="deleteObs(observation)">
                        <v-icon color="error">mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Eliminar</span>
                  </v-tooltip>
                </v-card-title>
                <v-card-text class="text-body-1" style="white-space: pre-wrap;">{{ observation.description
                }}</v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>

          <v-card v-else class="elevation-0" align="center" justify="center" color="transparent">
            <v-icon size="200" color="red">
              mdi-file
            </v-icon>
            <v-card-title class="justify-center">Não há registos a apresentar.</v-card-title>
          </v-card>
        </div>
      </v-card-text>
    </v-card>

    <v-dialog v-model="editDialog" presistent scrollable width="600" transition="dialog-bottom-transition">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark color="primary">
            <v-toolbar-title>{{editItem.id ? 'Editar' : 'Adicionar' }}</v-toolbar-title>
            <v-spacer />

            <v-btn icon dark @click="toggleDialog()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>

              <v-row align="center" justify="center">
                <v-col cols="12">
                  <v-text-field readonly v-model="client.name" label="Paciente"></v-text-field>
                </v-col>
              </v-row>

              <v-row align="center" justify="center">
                <v-col cols="12">
                  <v-textarea
                    :readonly="!($store.state.user.role === 'admin' || editItem.UserId === $store.state.user.id || !editItem.UserId)"
                    :clearable="($store.state.user.role === 'admin' || editItem.UserId === $store.state.user.id || !editItem.UserId)"
                    v-model="editItem.description" :rules="[rules.required]" label="Descrição" required></v-textarea>
                </v-col>
              </v-row>

            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions v-if="$store.state.user.role === 'admin' || editItem.UserId === $store.state.user.id || !editItem.UserId">
          <v-btn text color="red" outlined @click="clear">Limpar</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="primary" outlined @click="submit">Submeter</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-dialog>
</template>

<script>
import ServiceClients from "@/services/ServiceClients";
export default {
  name: "ClientObservations",
  data() {
    return {
      baseUrl: process.env.VUE_APP_API_URL,
      show: false,
      loading: false,
      valid: true,
      client: {},
      observations: [],
      editDialog: false,
      editItem: {},
      editIndex: -1,
      emptyItem: {},
      rules: {
        required: value => !!value || "Campo Obrigatório.",
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'E-mail inválido.';
        },
        counter: value =>
          (value && value.length <= 250) ||
          "Campo tem de ter menos de 250 caracteres",
        isValidNumber: value =>
          /^([0-9]+[.])?[0-9]+$/.test(value) || "Valor inválido"
      }
    };
  },
  methods: {
    async deleteObs(item) {

      if (confirm("Confirma a eliminação deste item?")) {
        try {

          let index = this.observations.indexOf(item);

          await ServiceClients.deleteObservation(item.id);

          this.observations.splice(index, 1);

          this.$store.dispatch("setSnackbar", {});
        } catch (error) {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: error
          });
        }
      }
    },
    async submit() {
      try {
        if (this.$refs.form.validate()) {
          let response;

          this.editItem.ClientId = this.client.id;
          this.editItem.UserId = this.$store.state.user.id;

          response = await ServiceClients.createUpdateObservation(this.editItem);

          if (this.editItem.id) {
            Object.assign(this.observations[this.editIndex], response.data);
          } else {
            this.observations.unshift(response.data);
          }

          this.$store.dispatch("setSnackbar", {});
          this.toggleDialog();
          this.clear();
        }
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    clear() {
      this.editItem.description = '';
    },
    close() {
      this.show = false;
    },
    toggleDialog(item) {
      if (item) {
        this.editIndex = this.observations.indexOf(item);
        this.editItem = Object.assign({}, item);
      } else {
        this.editIndex = -1;
        this.editItem = Object.assign({}, this.emptyItem);
      }

      this.editDialog = !this.editDialog;
    },
    async open(client) {
      this.client = client;
      this.observations = [];
      this.show = true;

      try {
        this.loading = true;
        this.observations = (await ServiceClients.getClientObservations(this.client.id)).data;

      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
      this.loading = false;
    }
  },
  computed: {
  },
  async mounted() {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
