import Api from '@/services/ServiceBase'
const path = 'calendar'

export default {
  getCalendar(userId, startDate, endDate) {
    return Api().get(path, {
      params: {
        userId,
        startDate,
        endDate
      }
    })
  },
  getCalendarByRoom(roomId,startDate, endDate) {
    return Api().get(path + '/CalendarByRoom', {
      params: {
        roomId,
        startDate,
        endDate
      }
    })
  },
  getCalendarByClient(clientId) {
    return Api().get(path + '/getCalendarByClient', {
      params: {
        clientId
      }
    })
  },
  sendMsg(eventId) {
    return Api().get(path + '/sendmsg', {
      params: {
        eventId
      }
    })
  },
  addEvent(event) {
    return Api().post(path + '/addevent', event)
  },
  bulkAddEvent(ClientId,RoomId,UserId,startDate,endDate,startTime,endTime,selectedDays,details) {
    return Api().post(path + '/bulkCreate', {
      ClientId,RoomId,UserId,startDate,endDate,startTime,endTime,selectedDays,details
    })
  },
  bulkEditEvent(linkedId,ClientId,RoomId,UserId,startDiference,endDiference,oldStartDate,details) {
    return Api().post(path + '/bulkEdit', {
      linkedId,ClientId,RoomId,UserId,startDiference,endDiference,oldStartDate,details
    })
  },
  bulkValidateForEdit(linkedId,RoomId,UserId,startDiference,endDiference,oldStartDate) {
    return Api().post(path + '/bulkValidateForEdit', {
      linkedId,RoomId,UserId,startDiference,endDiference,oldStartDate
    })
  },
  validateNewEvent(userId,roomId, start, end,eventId) {
    return Api().get(path + '/validatenewevent', {
      params: {
        userId,
        roomId,
        start,
        end,
        eventId
      }
    })
  },
  bulkValidate(userId,roomId, startDate, endDate,startTime,endTime,selectedDays) {
    return Api().get(path + '/bulkValidate', {
      params: {
        userId,
        roomId,
        startDate,
        endDate,
        startTime,
        endTime,
        selectedDays
      }
    })
  },
  delete(eventId) {
    return Api().get(path + `/delete/${eventId}`)
  },
  deletebulk(linkedId) {
    return Api().get(path + `/deletebulk/${linkedId}`)
  },
  getLinkedEventsUniqueDays(linkedId) {
    return Api().get(path + `/getLinkedEventsUniqueDays`, {
      params: {
        linkedId
      }
    })
  },
  bulkCreate(data) {
    return Api().post(path + '/bulkCreate', data)
  }
}
