<template>
  <v-layout ref="layout">
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-btn v-if="!phone" fixed dark fab bottom right large color="indigo" @click.stop="$refs.eventDialog.open()">
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <v-btn v-if="!phone" fixed dark fab bottom left large color="success" @click.stop="drawer = !drawer">
      <v-icon>mdi-magnify</v-icon>
    </v-btn>

    <v-navigation-drawer v-model="drawer" :mini-variant.sync="mini" :app="phone"
      style="height: 100%;background-color:#ededed;">

      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-icon v-if="!mini" x-large>mdi-account-circle</v-icon>
          <v-btn v-else icon>
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-list-item-avatar>

        <v-list-item-title>Funcionários</v-list-item-title>

        <v-btn v-if="!phone" icon @click.stop="mini = !mini">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense
        :style="(rooms.length != 1 && !phone) ? 'overflow-y: auto;height: ' + (paneheight * 0.5 - 58) + 'px;' : ''">
        <!-- <v-list dense> -->
        <v-list-item>
          <v-list-item-action>
            <v-checkbox @click="ToggleUserCalendar()" :input-value="bulkSelect" color="grey"></v-checkbox>
          </v-list-item-action>
          <v-text-field label="Filtro" v-model="filterUserKeyWord" @input="FilterUsers()" clearable></v-text-field>
        </v-list-item>

        <v-list-item v-for="item in filteredUsers" :key="item.id" link @click="hideEmploye(item)">
          <v-list-item-action>
            <v-checkbox :input-value="item.valid" :color="item.color"></v-checkbox>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list-item v-if="rooms.length != 1" class="px-2">
        <v-list-item-avatar>
          <v-icon v-if="!mini" x-large>mdi-home-outline</v-icon>
          <v-btn v-else icon>
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-list-item-avatar>

        <v-list-item-title>Salas</v-list-item-title>

        <v-btn v-if="!phone" icon @click.stop="mini = !mini">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>

      <v-divider></v-divider>

      <v-list v-if="rooms.length != 1" dense
        :style="!phone ? 'overflow-y: auto;height: ' + (paneheight * 0.5 - 58) + 'px;' : ''">
        <!-- <v-list v-if="rooms.length != 1" dense> -->

        <v-list-item>
          <v-list-item-action>
            <v-checkbox @click="ToggleRoomCalendar()" :input-value="bulkSelectRooms" color="grey"></v-checkbox>
          </v-list-item-action>
          <v-text-field label="Filtro" v-model="filterRoomKeyWord" @input="FilterRooms()" clearable></v-text-field>
        </v-list-item>

        <v-list-item v-for="item in filteredRooms" :key="item.id" link @click="hideRoom(item)">
          <v-list-item-action>
            <v-checkbox :input-value="item.valid" :color="item.color"></v-checkbox>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>

    </v-navigation-drawer>

    <v-container fluid class="grey lighten-5" style="padding:0">
      <v-row no-gutters style="height: 100%">
        <v-col cols="12">
          <v-sheet tile height="56" color="grey lighten-3" class="d-flex">
            <v-toolbar height="56" flat>

              <v-menu v-if="phone" right>
                <template v-slot:activator="{ on }">
                  <v-btn outlined color="grey darken-2" v-on="on">
                    <v-icon right>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="setToday">
                    <v-list-item-title>Hoje</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="$refs.eventDialog.open()">
                    <v-list-item-title>Adicionar</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="drawer = !drawer">
                    <v-list-item-title>Filtro</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn v-else outlined class="ma-2" color="grey darken-2" @click="setToday">Hoje</v-btn>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon class="ma-2" @click="PrevMonth" v-on="on">
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                </template>
                <span>{{ prevDate }}</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon class="ma-2" @click="NextMonth" v-on="on">
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </template>
                <span>{{ nextDate }}</span>
              </v-tooltip>

              <v-toolbar-title class="ma-2">{{ title }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-menu right>
                <template v-slot:activator="{ on }">
                  <v-btn outlined color="grey darken-2" v-on="on">
                    <span>{{ typeToLabel[type] }}</span>
                    <v-icon right>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title>Mês</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title>Semana</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'day'">
                    <v-list-item-title>Dia</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
          </v-sheet>
          <v-sheet :style="'height: ' + (paneheight - 56) + 'px;'">
            <v-calendar ref="calendar" locale="pt-PT" v-model="focus" :weekdays="weekday" :type="type" :events="events"
              :event-color="getEventColor" :event-overlap-mode="mode" :event-overlap-threshold="30" @click:date="viewDay"
              @click:event="showEvent" @change="setTitle" :first-time=$config.Calendar_First_Time
              :interval-count=$config.Calendar_Interval_Count :interval-minutes=$config.Calendar_Interval_Minutes>
              <template v-slot:event="{ event }">

                <span v-if="type === 'month'"
                  :style="rooms.length != 1 ? `background-image: linear-gradient(110deg,${event.userColor}  50%, ${event.roomColor} 50%);white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;width: 100%;height: 100%;` : `background-color: ${event.userColor} ;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;width: 100%;height: 100%;`">{{
                    event.name
                  }}</span>
                <span v-else
                  :style="rooms.length != 1 ? `white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;width: 100%;height: 100%;background-image: linear-gradient(0deg, ${event.roomColor} 50%, ${event.userColor} 50%);` :
                        `white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;width: 100%;height: 100%;background-color: ${event.userColor};`">{{
        event.name
      }}</span>

              </template>
            </v-calendar>
          </v-sheet>
        </v-col>
      </v-row>
      <event-dialog ref="eventDialog" :rooms="rooms" :users="users" @handleEvent="handleEvent"
        @handleDelete="handleDelete"></event-dialog>
    </v-container>
  </v-layout>
</template>

<script>
import PopUpSelector from "@/components/common/PopUpSelector";
import ServiceCalendars from "@/services/ServiceCalendars";
import { isMobile, getUsersAndRooms } from "@/components/Utils";
import EventDialog from "@/components/common/EventDialog.vue";
import _ from "lodash";

export default {
  name: "Calendar",
  components: {
    PopUpSelector
  },
  components: {
    EventDialog
  },
  data: () => ({
    settings: [],

    addingNewEvent: false,
    formValid: false,
    userPhasesEnabled: false,
    userProjectsEnabled: false,
    isLoadingUserProjects: false,
    eventDetails: "",
    selectedPatients: null,
    selectedPhase: null,
    clients: [],

    rooms: [],
    filteredRooms: [],
    filterRoomKeyWord: '',

    userPhases: [],
    phone: false,
    startDate: "",
    toggleStartDate: false,
    endDate: "",
    toggleEndDate: false,
    dialogCustomEvent: false,
    eventStart: "",
    toggleEventStart: false,
    eventEnd: "",
    toggleEventEnd: false,
    selectedDay: "",
    fab: false,
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,

    typeToLabel: {
      month: "Mês",
      week: "Semana",
      day: "Dia"
    },

    drawer: null,
    mini: false,
    focus: "",
    mode: "stack",
    weekday: [0, 1, 2, 3, 4, 5, 6],
    includeInactive: false,
    selectedUser: null,
    selectedRoom: null,
    selectedMachine: null,
    loading: true,
    showDialog: false,

    users: [],
    filteredUsers: [],
    filterUserKeyWord: '',

    paneheight: 0,
    dark: false,
    startMenu: false,
    endMenu: false,
    nowMenu: false,
    minWeeks: 1,
    now: null,
    type: "month",
    types: [
      { text: "Mês", value: "month" },
      { text: "Semana", value: "week" },
      { text: "Dia", value: "day" }
    ],
    color: "primary",
    months: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro"
    ],
    show: false,
    colors: [],
    status: [],
    monthsProcessed: [],
    events: [],
    fullEvents: [],
    start: null,
    end: null,
    title: "",
    prevDate: "Mês Anterior",
    nextDate: "Mês Seguinte",
    bulkSelect: true,
    bulkSelectRooms: true,
    calMoveDir: null
  }),
  methods: {
    FilterRooms() {
      if (!this.filterRoomKeyWord) {
        this.filteredRooms = this.rooms;
      }
      this.filteredRooms = _.filter(this.rooms, (o) => { return o.name.toLowerCase().includes(this.filterRoomKeyWord.toLowerCase()); });
    },
    FilterUsers() {
      if (!this.filterUserKeyWord) {
        this.filteredUsers = this.users;
      }
      this.filteredUsers = _.filter(this.users, (o) => { return o.name.toLowerCase().includes(this.filterUserKeyWord.toLowerCase()); });
    },
    async setTitle({ start, end }) {
      if (!start || !end) {
        return;
      }
      const startMonth = this.months[start.month - 1];
      const endMonth = this.months[end.month - 1];
      //   const suffixMonth = startMonth === endMonth ? "" : endMonth;
      const suffixMonth = endMonth;
      const startYear = start.year;
      const endYear = end.year;
      //   const suffixYear = startYear === endYear ? "" : endYear;
      const suffixYear = endYear;
      const startDay = start.day;
      const endDay = end.day;

      switch (this.type) {
        case "month":
          this.prevDate = "Mês Anterior";
          this.nextDate = "Mês Seguinte";
          this.title = `${startMonth} ${startYear}`;
          break;
        case "week":
          this.prevDate = "Semana Anterior";
          this.nextDate = "Semana Seguinte";
          this.title = `${startDay} ${startMonth} ${startYear} - ${endDay} ${suffixMonth} ${suffixYear}`;
          break;
        case "day":
          this.prevDate = "Dia Anterior";
          this.nextDate = "Dia Seguinte";
          this.title = `${startDay} ${startMonth} ${startYear}`;
          break;
      }

      if (this.calMoveDir !== null) {
        this.getCalendar();
      }
    },
    setToday() {
      this.focus = this.today;
    },
    hideEmploye(item) {
      item.valid = !item.valid;

      let cutElements;
      if (item.valid) {
        cutElements = _.filter(this.fullEvents, { UserId: item.id });

        cutElements.forEach(element => {
          if (_.findIndex(this.events, ['id', element.id]) == -1) {
            this.events.push(element);
          }
        });
      } else {
        let keepRooms = _.filter(this.rooms, ['valid', true]);
        this.events = _.filter(this.events, o => {
          return (o.UserId != item.id || _.findIndex(keepRooms, ['id', o.RoomId]) != -1);
        });
      }
    },
    hideRoom(item) {
      item.valid = !item.valid;

      let cutElements;
      if (item.valid) {
        cutElements = _.filter(this.fullEvents, { RoomId: item.id });

        cutElements.forEach(element => {
          if (_.findIndex(this.events, ['id', element.id]) == -1) {
            this.events.push(element);
          }
        });
      } else {
        let keepEmployees = _.filter(this.users, ['valid', true]);
        this.events = _.filter(this.events, o => {
          return (o.RoomId != item.id || _.findIndex(keepEmployees, ['id', o.UserId]) != -1);
        });
      }
    },
    handleDelete(id) {

      let mIds = [];
      mIds = mIds.concat(id);

      mIds.forEach(id => {
        let index = -1;
        index = _.findIndex(this.fullEvents, { id });
        if (index != -1) this.fullEvents.splice(index, 1);

        index = _.findIndex(this.events, { id });
        if (index != -1) this.events.splice(index, 1);
      });

    },
    showEvent({ nativeEvent, event }) {
      this.$refs.eventDialog.open(event);

      nativeEvent.stopPropagation();
    },
    viewDay({ date }) {
      this.calMoveDir = true;
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    showIntervalLabel(interval) {
      return interval.minute === 0;
    },
    handleEvent(events) {

      let mEvents = [];
      mEvents = mEvents.concat(events);

      mEvents.forEach(event => {

        event.category = (_.find(this.users, { 'id': event.UserId })).name;
        event.color = event.roomColor;

        const index = _.findIndex(this.events, { 'id': event.id });

        if (index === -1) {

          if (!_.find(this.fullEvents, ["id", event.id])) {
            this.fullEvents.push(event);
          }

          if (_.find(this.users, ["id", event.UserId]).valid) {
            this.events.push(event);
          }
        } else {
          if (!_.find(this.fullEvents, ["id", event.id])) {
            this.fullEvents.splice(index, 1, event);
          }

          if (_.find(this.users, ["id", event.UserId]).valid) {
            this.events.splice(index, 1, event);
          }
        }
      });
    },
    ToggleUserCalendar() {
      this.bulkSelect = !this.bulkSelect;

      if (this.bulkSelect) {
        this.events = this.fullEvents;
      } else {
        // this.events = [];

        let keepRooms = _.filter(this.rooms, ['valid', true]);
        this.events = _.filter(this.events, o => {
          return (_.findIndex(keepRooms, ['id', o.RoomId]) != -1);
        });
      }

      _.forEach(this.users, value => {
        value.valid = this.bulkSelect;
      });
    },
    ToggleRoomCalendar() {
      this.bulkSelectRooms = !this.bulkSelectRooms;

      if (this.bulkSelectRooms) {
        this.events = this.fullEvents;
      } else {
        // this.events = [];

        let keepEmployees = _.filter(this.users, ['valid', true]);
        this.events = _.filter(this.events, o => {
          return (_.findIndex(keepEmployees, ['id', o.UserId]) != -1);
        });
      }

      _.forEach(this.rooms, value => {
        value.valid = this.bulkSelectRooms;
      });
    },
    async NextMonth() {
      this.calMoveDir = true;
      this.$refs.calendar.next();
      // await this.getCalendar(this.$refs.calendar.lastEnd.month + 1);
    },
    async PrevMonth() {
      this.calMoveDir = false;
      this.$refs.calendar.prev();
      // await this.getCalendar(this.$refs.calendar.lastStart.month - 1);
    },
    async getCalendar() {
      try {
        let month;
        let year;
        if (this.calMoveDir) {
          month = this.$refs.calendar.lastEnd.month;
          year = this.$refs.calendar.lastEnd.year;
        } else {
          month = this.$refs.calendar.lastStart.month;
          year = this.$refs.calendar.lastStart.year;
        }

        let newMOnth = year + "_" + month;

        if (_.includes(this.monthsProcessed, newMOnth)) {
          return;
        }

        this.loading = true;

        this.tracked = [];
        this.colors = [];
        this.status = [];

        let startDate = "";

        if (month == 0) {
          startDate = year - 1 + "-12-01";
        } else {
          startDate = year + "-" + month.toString().padStart(2, "0") + "-01";
        }

        let endDate = "";

        if (month == 12) {
          endDate = year + 1 + "-01-01";
        } else {
          endDate =
            year + "-" + (month + 1).toString().padStart(2, "0") + "-01";
        }

        const response = await ServiceCalendars.getCalendar(
          null,
          startDate,
          endDate
        );

        _.forEach(response.data, value => {
          // value.color =
          //   "#" +
          //   Math.floor(rng() * 16777215)
          //     .toString(16)
          //     .padEnd(6, "0");

          //   this.fullEvents.push(value);
          // value.auxColor = value.color;
          // value.color = '';
          // value.roomColor = '#FFFFFF';

          if (!_.find(this.fullEvents, ["id", value.id])) {
            this.fullEvents.push(value);
          }

          const index = _.findIndex(this.events, { 'id': value.id });

          if (index === -1) {
            if (_.find(this.users, ["id", value.UserId]).valid) {
              this.events.push(value);
            }
          }
        });

        this.monthsProcessed.push(newMOnth);

        let i = 0;
        let currentDate;
        let stopDate;
        let formattedDate;
        let newProj;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    resizeEvent() {
      this.paneheight = document.documentElement.clientHeight - 64;
    }
  },
  async mounted() {
    try {
      this.phone = isMobile();

      if (this.phone) {
        this.drawer = false;
      } else {
        this.drawer = true;
      }

      this.$refs.calendar.checkChange();
      this.paneheight = document.documentElement.clientHeight - 64;

      await getUsersAndRooms(1, 1, this.users, this.rooms);

      if (this.users.length == 1) {
        this.selectedUser = this.users[0];
      }

      if (this.rooms.length == 1) {
        this.selectedRoom = this.rooms[0];
        this.rooms[0].valid = false;
      }

      this.filteredUsers = this.filteredUsers.concat(this.users);
      this.filteredRooms = this.filteredRooms.concat(this.rooms);

      await this.getCalendar(this.$refs.calendar.lastStart.month);
    } catch (error) {
      this.$store.dispatch("setSnackbar", {
        color: "error",
        text: error
      });
    }
  },
  created: function () {
    window.addEventListener("resize", this.resizeEvent);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeEvent);
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
