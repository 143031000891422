<template>
  <v-card class="elevation-0">
    <v-card-text>
      <v-container>
        <v-row align="center" justify="center">
          <v-col cols="12" lg="6">

            <v-switch v-model="sendSms.value" @change="updateSettingBool(sendSms)" value="1"
              label="Permitir envio de SMS?"></v-switch>
            <v-divider></v-divider>
          </v-col>
        </v-row>

        <v-row align="center" justify="center">
          <v-col cols="12" lg="6">

            <v-textarea v-model="templateConfirmSms.value" outlined append-outer-icon="mdi-content-save"
              hint="data = {date} / funcionário = {empName}" counter maxlength="400" persistent-hint
              label="Mensagem Confirmação de Marcação"
              @click:append-outer="updateSettingText(templateConfirmSms)"></v-textarea>
            <v-divider></v-divider>
          </v-col>
        </v-row>

        <v-row align="center" justify="center">
          <v-col cols="12" lg="6">

            <v-textarea v-model="templateWarnSms.value" outlined append-outer-icon="mdi-content-save"
              hint="data = {date} / funcionário = {empName}" counter maxlength="400" persistent-hint
              label="Mensagem Lembrete de Marcação" @click:append-outer="updateSettingText(templateWarnSms)"></v-textarea>
          </v-col>
        </v-row>
      </v-container>
      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-card-text>
  </v-card>
</template>
  
<script>
import ServiceSettings from "@/services/ServiceSettings";
export default {
  name: "Settings-Edit",
  data: () => ({
    valid: true,
    loading: false,
    sendSms: {},
    templateConfirmSms: {},
    templateWarnSms: {},
  }),
  methods: {
    async updateSettingBool(setting) {
      try {
        this.loading = true;

        (await ServiceSettings.update({
          name: setting.name,
          value: (setting.value ? '1' : '0')
        })).data;

        this.$store.dispatch("setSnackbar", {});

        this.loading = false;
      } catch (error) {
        setting.value = (setting.value ? '1' : '0');
        this.loading = false;
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    async updateSettingText(setting) {
      try {
        this.loading = true;

        (await ServiceSettings.update({
          name: setting.name,
          value: setting.value
        })).data;

        this.$store.dispatch("setSnackbar", {});

        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    }
  },
  async mounted() {
    try {
      this.loading = true;

      this.sendSms = (await ServiceSettings.show('sendSms')).data;
      this.templateConfirmSms = (await ServiceSettings.show('templateConfirmSms')).data;
      this.templateWarnSms = (await ServiceSettings.show('templateWarnSms')).data;

      this.loading = false;
    } catch (error) {
      this.loading = false;
      this.$store.dispatch("setSnackbar", {
        color: "error",
        text: error
      });
    }
  }
};
</script>
<style></style>
  