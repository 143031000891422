<template>
  <v-dialog v-model="show" scrollable width="600" transition="dialog-bottom-transition">
    <v-card class="basicBackGround">
      <v-toolbar dark color="light-blue">
        <v-toolbar-title>Marcações : {{ client.name }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon @click="$refs.eventDialog.open(null, client.id, client.name)">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Novo Registo</span>
        </v-tooltip>
      </v-toolbar>
      <v-card-text>

        <div v-if="loading" align="center" justify="center">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </div>
        <div>
          <v-timeline v-show="showAppointments" ref="mTimeLine" clipped dense>
            <v-timeline-item v-for="appointment in appointments" :key="appointment.id" :id="appointment.scroll" large>
              <template v-slot:icon>
                <v-avatar>
                  <img :src="baseUrl + '/' + appointment.img">
                </v-avatar>
              </template>
              <v-card class="elevation-2" :color="appointment.backGround"
                @click="$refs.eventDialog.open(appointment, client.id, client.name)">
                <v-card-title class="text-body-1">
                  <p>{{ `${appointment.start} a ${appointment.end}` }}</p>
                  <!-- <p>{{ observation.room}}</p> -->
                  <v-spacer></v-spacer>
                  <!-- <v-tooltip bottom v-if="$store.state.user.role === 'admin' || editItem.UserId === $store.state.user.id">
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon @click="deleteObs(observation)">
                        <v-icon color="error">mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Eliminar</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn v-on="on" icon @click="toggleDialog(observation)">
                        <v-icon v-if="$store.state.user.role === 'admin' || editItem.UserId === $store.state.user.id"
                          color="success">mdi-pencil</v-icon>
                        <v-icon v-else color="success">mdi-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ ($store.state.user.role === 'admin' || editItem.UserId === $store.state.user.id) ? 'Editar' :
                      'Ver' }}</span>
                  </v-tooltip> -->
                </v-card-title>
                <v-card-text class="text-body-1" style="white-space: pre-wrap;">{{ appointment.roomName }}</v-card-text>
              </v-card>
            </v-timeline-item>
          </v-timeline>

          <v-card v-show="!showAppointments" class="elevation-0" align="center" justify="center" color="transparent">
            <v-icon size="200" color="red">
              mdi-file
            </v-icon>
            <v-card-title class="justify-center">Não há registos a apresentar.</v-card-title>
          </v-card>
        </div>
      </v-card-text>
    </v-card>
    <event-dialog ref="eventDialog" :rooms="rooms" :users="users" @handleEvent="handleEvent"
      @handleDelete="handleDelete"></event-dialog>
  </v-dialog>
</template>

<script>
import ServiceCalendars from "@/services/ServiceCalendars";
import EventDialog from "@/components/common/EventDialog.vue";
export default {
  name: "ClientAppointments",
  props: ["users", "rooms"],
  components: {
    EventDialog
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_API_URL,
      show: false,
      loading: false,
      client: {},
      appointments: [],
      showAppointments: true,
      hasOpenEvent: false
    };
  },
  methods: {
    async handleDelete(id) {
      
      let mIds = [];
      mIds = mIds.concat(id);

      mIds.forEach(id => {
        let index = -1;
        index = _.findIndex(this.events, { id });
        if (index != -1) this.appointments.splice(index, 1);
      });

      for (let i = 0; i < this.appointments.length; i++) {
        this.appointments[i].backGround = '';
        this.appointments[i].scroll = "";
      }

      this.setNextAppointment();

    },
    async handleEvent(events) {
      this.appointments = this.appointments.concat(events);

      this.appointments.sort(function (a, b) {
        return (a.start > b.start);
      });

      for (let i = 0; i < this.appointments.length; i++) {
        this.appointments[i].backGround = '';
        this.appointments[i].scroll = "";
      }

      this.setNextAppointment();

    },
    setNextAppointment() {
      try {
        if (this.appointments.length == 0) {
          this.showAppointments = false;
        } else {
          this.showAppointments = true;
        }

        const now = new Date();
        let eventDate;
        for (let i = 0; i < this.appointments.length; i++) {
          eventDate = new Date(this.appointments[i].start);
          if (eventDate >= now) {
            this.appointments[i].backGround = 'light-blue';
            if (i === 0) {
              this.appointments[i].scroll = "nextAppointment";
            } else {
              this.appointments[i - 1].scroll = "nextAppointment";
            }
            break;
          }
        }

        this.$refs.mTimeLine.$nextTick(() => {
          const scrollElement = document.getElementById("nextAppointment");
          if (scrollElement) {
            scrollElement.scrollIntoView();
          }
        });
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    close() {
      this.show = false;
    },
    async open(client) {
      this.showAppointments = true;
      this.client = client;
      this.appointments = [];
      this.show = true;

      try {
        this.loading = true;
        this.appointments = (await ServiceCalendars.getCalendarByClient(this.client.id)).data;

        this.setNextAppointment();

      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
      this.loading = false;
    }
  },
  computed: {
  },
  async mounted() {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
