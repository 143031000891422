<template>
  <v-dialog v-model="dialog" scrollable persistent max-width="600" transition="dialog-bottom-transition">

    <v-overlay :value="processing">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

    <v-card>
      <observations ref="observations" />
      <add-edit-client ref="addEditClient" @submited="submitedData" />
      <v-toolbar dark color="primary">
        <v-toolbar-title>Marcação</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon :disabled="processing" @click="dialog = dialog = !dialog">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-form ref="newEventForm" v-model="formValid" lazy-validation>
          <v-tabs v-if="showTab" v-model="tab" centered>
            <v-tab>
              Normal
            </v-tab>
            <v-tab>
              Periódica
            </v-tab>

            <v-tab-item>
              <v-container>
                <v-row>
                  <v-col cols="12" md="6" sm="12">
                    <v-dialog ref="toggleStartDate" v-model="toggleStartDate" :return-value.sync="event.startDate"
                      persistent width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field :disabled="locked" clearable v-model="event.startDate" label="Data Inicio"
                          prepend-icon="mdi-clock-outline" required :rules="[rules.required]" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker :max="event.endDate" locale="pt-pt" v-model="event.startDate" scrollable>
                        <v-btn text color="primary" @click="toggleStartDate = false">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="$refs.toggleStartDate.save(event.startDate)">OK</v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col cols="12" md="6" sm="12">
                    <v-dialog ref="toggleStartHour" v-model="toggleStartHour" :return-value.sync="event.startHour"
                      persistent width="290px"
                      @input="value => value && $refs.picker1 && ($refs.picker1.selectingHour = true)">
                      <template v-slot:activator="{ on }">
                        <v-text-field :disabled="locked" v-model="event.startHour" label="Hora Inicio"
                          prepend-icon="mdi-clock-outline" readonly required :rules="[rules.required]" clearable
                          v-on="on"></v-text-field>
                      </template>
                      <v-time-picker v-if="toggleStartHour" format="24hr"
                        :max="event.startDate === event.endDate ? event.endHour : ''" locale="pt-pt"
                        v-model="event.startHour" ref="picker1">
                        <v-btn text color="primary" @click="toggleStartHour = false">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="$refs.toggleStartHour.save(event.startHour)">OK</v-btn>
                      </v-time-picker>
                    </v-dialog>
                  </v-col>
                </v-row>
                <v-row no-gutters align="center" justify="center" v-if="!locked">
                  <v-col cols="6" align="center" justify="center">
                    <v-btn text :disabled="(event.startDate && event.startHour) ? false : true" color="primary" outlined
                      @click="addTime(0.5)">
                      +30min
                    </v-btn>
                  </v-col>
                  <v-col cols="6" align="center" justify="center">
                    <v-btn text :disabled="(event.startDate && event.startHour) ? false : true" color="primary" outlined
                      @click="addTime(1)">
                      +1 hora
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" md="6" sm="12">
                    <v-dialog ref="toggleEndDate" v-model="toggleEndDate" :return-value.sync="event.endDate" persistent
                      width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field :disabled="locked" clearable v-model="event.endDate" label="Data Fim"
                          prepend-icon="mdi-clock-outline" required :rules="[rules.required]" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker :min="event.startDate" locale="pt-pt" v-model="event.endDate" scrollable>
                        <v-btn text color="primary" @click="toggleEndDate = false">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="$refs.toggleEndDate.save(event.endDate)">OK</v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col cols="12" md="6" sm="12">
                    <v-dialog ref="toggleEndHour" v-model="toggleEndHour" :return-value.sync="event.endHour" persistent
                      width="290px" @input="value => value && $refs.picker2 && ($refs.picker2.selectingHour = true)">
                      <template v-slot:activator="{ on }">
                        <v-text-field :disabled="locked" v-model="event.endHour" label="Hora Fim"
                          prepend-icon="mdi-clock-outline" readonly clearable required :rules="[rules.required]"
                          v-on="on"></v-text-field>
                      </template>
                      <v-time-picker v-if="toggleEndHour" format="24hr"
                        :min="event.startDate === event.endDate ? event.startHour : ''" locale="pt-pt"
                        v-model="event.endHour" ref="picker2">
                        <v-btn text color="primary" @click="toggleEndHour = false">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="$refs.toggleEndHour.save(event.endHour)">OK</v-btn>
                      </v-time-picker>
                    </v-dialog>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
            <v-tab-item>
              <v-container>
                <v-row no-gutters>
                  <v-col cols="12" md="6" sm="12">
                    <v-dialog ref="bulkToggleStartDate" v-model="bulkToggleStartDate"
                      :return-value.sync="event.bulkStartDate" persistent width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field :disabled="locked" clearable v-model="event.bulkStartDate" label="Data Inicio"
                          prepend-icon="mdi-clock-outline" required :rules="[rules2.required]" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker :max="event.bulkEndDate" locale="pt-pt" v-model="event.bulkStartDate" scrollable>
                        <v-btn text color="primary" @click="bulkToggleStartDate = false">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary"
                          @click="$refs.bulkToggleStartDate.save(event.bulkStartDate)">OK</v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>


                  <v-col cols="12" md="6" sm="12">
                    <v-dialog ref="bulkToggleEndDate" v-model="bulkToggleEndDate" :return-value.sync="event.bulkEndDate"
                      persistent width="290px">
                      <template v-slot:activator="{ on }">
                        <v-text-field :disabled="locked" clearable v-model="event.bulkEndDate" label="Data Fim"
                          prepend-icon="mdi-clock-outline" required :rules="[rules2.required]" v-on="on"></v-text-field>
                      </template>
                      <v-date-picker :min="event.bulkStartDate" locale="pt-pt" v-model="event.bulkEndDate" scrollable>
                        <v-btn text color="primary" @click="bulkToggleEndDate = false">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="$refs.bulkToggleEndDate.save(event.bulkEndDate)">OK</v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>

                </v-row>
                <v-row no-gutters><v-col cols="12" md="6" sm="12">
                    <v-dialog ref="bulkToggleStartHour" v-model="bulkToggleStartHour"
                      :return-value.sync="event.bulkStartHour" persistent width="290px"
                      @input="value => value && $refs.picker1 && ($refs.picker1.selectingHour = true)">
                      <template v-slot:activator="{ on }">
                        <v-text-field :disabled="locked" v-model="event.bulkStartHour" label="Hora Inicio"
                          prepend-icon="mdi-clock-outline" readonly required :rules="[rules2.required]" clearable
                          v-on="on"></v-text-field>
                      </template>
                      <v-time-picker v-if="bulkToggleStartHour" format="24hr" :max="event.bulkEndHour" locale="pt-pt"
                        v-model="event.bulkStartHour" ref="picker1">
                        <v-btn text color="primary" @click="bulkToggleStartHour = false">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary"
                          @click="$refs.bulkToggleStartHour.save(event.bulkStartHour)">OK</v-btn>
                      </v-time-picker>
                    </v-dialog>
                  </v-col>
                  <v-col cols="12" md="6" sm="12">
                    <v-dialog ref="bulkToggleEndHour" v-model="bulkToggleEndHour" :return-value.sync="event.bulkEndHour"
                      persistent width="290px"
                      @input="value => value && $refs.picker2 && ($refs.picker2.selectingHour = true)">
                      <template v-slot:activator="{ on }">
                        <v-text-field :disabled="locked" v-model="event.bulkEndHour" label="Hora Fim"
                          prepend-icon="mdi-clock-outline" readonly clearable required :rules="[rules2.required]"
                          v-on="on"></v-text-field>
                      </template>
                      <v-time-picker v-if="bulkToggleEndHour" format="24hr" :min="event.bulkStartHour" locale="pt-pt"
                        v-model="event.bulkEndHour" ref="picker2">
                        <v-btn text color="primary" @click="bulkToggleEndHour = false">Cancelar</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" @click="$refs.bulkToggleEndHour.save(event.bulkEndHour)">OK</v-btn>
                      </v-time-picker>
                    </v-dialog>
                  </v-col>
                </v-row>

                <v-row no-gutters align="center" justify="center">
                  <v-col cols="12" align="center" justify="center">
                    <v-item-group multiple v-model="event.selectedDays" align="center" justify="center">
                      <v-subheader>Repetir :</v-subheader>
                      <v-item :value="0" v-slot="{ active, toggle }">
                        <v-btn class="ma-1" :input-value="active" active-class="primary white--text" depressed rounded
                          @click="toggle">Dom</v-btn>
                      </v-item>

                      <v-item :value="1" v-slot="{ active, toggle }">
                        <v-btn class="ma-1" :input-value="active" active-class="primary white--text" depressed rounded
                          @click="toggle">Seg</v-btn>
                      </v-item>

                      <v-item :value="2" v-slot="{ active, toggle }">
                        <v-btn class="ma-1" :input-value="active" active-class="primary white--text" depressed rounded
                          @click="toggle">Ter</v-btn>
                      </v-item>

                      <v-item :value="3" v-slot="{ active, toggle }">
                        <v-btn class="ma-1" :input-value="active" active-class="primary white--text" depressed rounded
                          @click="toggle">Qua</v-btn>
                      </v-item>

                      <v-item :value="4" v-slot="{ active, toggle }">
                        <v-btn class="ma-1" :input-value="active" active-class="primary white--text" depressed rounded
                          @click="toggle">Qui</v-btn>
                      </v-item>

                      <v-item :value="5" v-slot="{ active, toggle }">
                        <v-btn class="ma-1" :input-value="active" active-class="primary white--text" depressed rounded
                          @click="toggle">Sex</v-btn>
                      </v-item>

                      <v-item :value="6" v-slot="{ active, toggle }">
                        <v-btn class="ma-1" :input-value="active" active-class="primary white--text" depressed rounded
                          @click="toggle">Sab</v-btn>
                      </v-item>
                    </v-item-group>
                  </v-col>

                  <v-col cols="12" align="center" justify="center" v-if="event.selectedDays.length == 0">
                    <span style="color: red;">Tem de escolher pelo menos 1 dia.</span>
                  </v-col>
                </v-row>
              </v-container>
            </v-tab-item>
          </v-tabs>
          <v-container v-else>
            <v-row>
              <v-col cols="12" md="6" sm="12">
                <v-dialog ref="toggleStartDate" v-model="toggleStartDate" :return-value.sync="event.startDate" persistent
                  width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field :disabled="locked" clearable v-model="event.startDate" label="Data Inicio"
                      prepend-icon="mdi-clock-outline" required :rules="[rules.required]" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker locale="pt-pt" v-model="event.startDate" scrollable>
                    <v-btn text color="primary" @click="toggleStartDate = false">Cancelar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="saveStartDate(event.startDate)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-dialog ref="toggleStartHour" v-model="toggleStartHour" :return-value.sync="event.startHour" persistent
                  width="290px" @input="value => value && $refs.picker1 && ($refs.picker1.selectingHour = true)">
                  <template v-slot:activator="{ on }">
                    <v-text-field :disabled="locked" v-model="event.startHour" label="Hora Inicio"
                      prepend-icon="mdi-clock-outline" readonly required :rules="[rules.required]" clearable
                      v-on="on"></v-text-field>
                  </template>
                  <v-time-picker v-if="toggleStartHour" format="24hr"
                    :max="event.startDate === event.endDate ? event.endHour : ''" locale="pt-pt" v-model="event.startHour"
                    ref="picker1">
                    <v-btn text color="primary" @click="toggleStartHour = false">Cancelar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="$refs.toggleStartHour.save(event.startHour)">OK</v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row no-gutters align="center" justify="center" v-if="!locked">
              <v-col cols="6" align="center" justify="center">
                <v-btn text :disabled="(event.startDate && event.startHour) ? false : true" color="primary" outlined
                  @click="addTime(0.5)">
                  +30min
                </v-btn>
              </v-col>
              <v-col cols="6" align="center" justify="center">
                <v-btn text :disabled="(event.startDate && event.startHour) ? false : true" color="primary" outlined
                  @click="addTime(1)">
                  +1 hora
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6" sm="12">
                <v-dialog ref="toggleEndDate" v-model="toggleEndDate" :return-value.sync="event.endDate" persistent
                  width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field :disabled="locked" clearable v-model="event.endDate" label="Data Fim"
                      prepend-icon="mdi-clock-outline" required :rules="[rules.required]" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker :min="event.startDate" locale="pt-pt" v-model="event.endDate" scrollable>
                    <v-btn text color="primary" @click="toggleEndDate = false">Cancelar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="$refs.toggleEndDate.save(event.endDate)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
              <v-col cols="12" md="6" sm="12">
                <v-dialog ref="toggleEndHour" v-model="toggleEndHour" :return-value.sync="event.endHour" persistent
                  width="290px" @input="value => value && $refs.picker2 && ($refs.picker2.selectingHour = true)">
                  <template v-slot:activator="{ on }">
                    <v-text-field :disabled="locked" v-model="event.endHour" label="Hora Fim"
                      prepend-icon="mdi-clock-outline" readonly clearable required :rules="[rules.required]"
                      v-on="on"></v-text-field>
                  </template>
                  <v-time-picker v-if="toggleEndHour" format="24hr"
                    :min="event.startDate === event.endDate ? event.startHour : ''" locale="pt-pt" v-model="event.endHour"
                    ref="picker2">
                    <v-btn text color="primary" @click="toggleEndHour = false">Cancelar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="$refs.toggleEndHour.save(event.endHour)">OK</v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-col>
            </v-row>
          </v-container>

          <v-container>
            <v-row no-gutters>
              <v-col cols="12" sm="12">
                <!-- <v-text-field label="Funcionário" placeholder="Placeholder"></v-text-field> -->
                <!-- <pop-up-selector v-if="phone" :itemList="users" :id="'id'" :title="'Funcionários'" :label="'Funcionário'"
                  :description="'name'" :fullReturn="true" :selectedItem="selectedUser === null ? '' : selectedUser.name"
                  @selection="selectuser" :rules="rules" /> -->
                <v-autocomplete :disabled="!lockUser" v-model="event.UserId" :items="users" item-text="name"
                  item-value="id" label="Funcionário" required :rules="generalRules"></v-autocomplete>
              </v-col>
            </v-row>
            <v-row no-gutters v-if="rooms.length != 1">
              <v-col cols="12" sm="12">
                <!-- <v-text-field label="Funcionário" placeholder="Placeholder"></v-text-field> -->
                <!-- <pop-up-selector v-if="phone" :itemList="users" :id="'id'" :title="'Funcionários'" :label="'Funcionário'"
                  :description="'name'" :fullReturn="true" :selectedItem="selectedUser === null ? '' : selectedUser.name"
                  @selection="selectuser" :rules="rules" /> -->
                <v-autocomplete v-model="event.RoomId" :items="rooms" item-text="name" item-value="id" label="Sala"
                  required :rules="generalRules" :disabled="rooms.length == 1 || locked"></v-autocomplete>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" sm="12">
                <!-- <pop-up-selector v-if="phone" :itemList="clients" :id="'id'" :title="'Projectos'" :label="'Paciente'"
                  :description="'title'" :fullReturn="true"
                  :selectedItem="selectedPatients === null ? '' : selectedPatients.title" @selection="selectproject"
                  :rules="rules" /> -->

                <!-- <v-autocomplete v-else label="Paciente(s)" chips v-model="selectedPatients" multiple :items="clients"
                  item-text="name" item-value="id" required :rules="rules" clearable></v-autocomplete> -->
                <v-autocomplete :disabled="locked || lockClient" ref="cbClient" :label="clientType" :filter="customFilter"
                  :loading="loadingClients" v-debounce="newVal => myFn(newVal, 'v-text-field')" v-model="event.ClientId"
                  :items="clients" item-text="name" item-value="id" :return-object="false" required :rules="generalRules"
                  clearable :append-outer-icon="'mdi-plus'" @click:append-outer="openClientInfoEmpty()"
                  hint="Nome, NIF ou Tlf." persistent-hint> <template v-slot:progress>
                    <span class="custom-loader">
                      <v-icon light>mdi-cached</v-icon>
                    </span>
                  </template></v-autocomplete>
              </v-col>
            </v-row>
            <v-row no-gutters align="center" justify="center">
              <v-col cols="6" sm="4" align="center" justify="center">
                <v-btn text :disabled="event.ClientId ? false : true" color="primary" outlined @click="openClientInfo">
                  Ficha
                </v-btn>
              </v-col>
              <v-col cols="6" sm="4" align="center" justify="center">
                <v-btn text :disabled="event.ClientId ? false : true" color="primary" outlined @click="openObs">
                  Observações
                </v-btn>
              </v-col>
              <v-col cols="6" sm="4" align="center" justify="center"
                v-if="$store.state.user.settings.sendSms && event.id">
                <v-btn text :disabled="event.ClientId ? false : true" color="primary" outlined @click="sendSms">
                  SMS
                </v-btn>
              </v-col>
            </v-row>
            <v-row no-gutters>
              <v-col cols="12" sm="12">
                <v-textarea :disabled="locked" label="Detalhes" v-model="event.details"></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-form>

      </v-card-text>
      <v-card-actions v-if="!locked">
        <v-btn @click="DeleteSelectedEvent" text color="red" :disabled="processing">Eliminar</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="createEventChooser" text color="primary" :disabled="processing">Confirmar</v-btn>
      </v-card-actions>
    </v-card>

    <confirm-dialog ref="confirm" />
  </v-dialog>
</template>

<script>
import _ from "lodash";
import ServiceCalendars from "@/services/ServiceCalendars";
import ServiceClients from "@/services/ServiceClients";
import moment from "moment";
import Observations from "@/components/client/Observations.vue";
import ConfirmDialog from "@/components/common/ConfirmDialog.vue";
import AddEditClient from "@/components/common/AddEditClient.vue";
// import backButton from '@/mixins/backButton';

export default {
  name: "Event-Dialog",
  props: ["users", "rooms"],
  components: {
    Observations,
    AddEditClient,
    ConfirmDialog
  },
  data() {
    return {

      tab: 0,
      showTab: false,

      generalRules: [v => !!v || "Campo obrigatório"],
      rules: {
        required: value => {
          let result = true;
          if (this.tab === 0) {
            if (!value) {
              result = "Campo Obrigatório.";
            }
          } else {
            result = true;
          }
          return result;
        }
      },
      rules2: {
        required: value => {
          let result = true;
          if (this.tab === 1) {
            if (!value) {
              result = "Campo Obrigatório.";
            }
          } else {
            result = true;
          }
          return result;
        }
      },
      showDayError: false,

      dialog: false,
      dayChangeDialog: false,
      search: "",
      formValid: true,
      filteredList: [],
      clients: [],
      toggleStartDate: false,
      toggleEndDate: false,
      toggleStartHour: false,
      toggleEndHour: false,

      bulkToggleStartDate: false,
      bulkToggleEndDate: false,
      bulkToggleStartHour: false,
      bulkToggleEndHour: false,

      weekDays: [
        {
          description: 'Domingo',
          id: 0
        },
        {
          description: 'Segunda',
          id: 1
        },
        {
          description: 'Terça',
          id: 2
        },
        {
          description: 'Quarta',
          id: 3
        },
        {
          description: 'Quinta',
          id: 4
        },
        {
          description: 'Sexta',
          id: 5
        },
        {
          description: 'Sábado',
          id: 6
        }
      ],

      oldEvent: {},
      event: {
        startDate: "",
        endDate: "",
        startHour: "",
        endHour: "",
        bulkStartDate: "",
        bulkEndDate: "",
        bulkStartHour: "",
        bulkEndHour: "",
        UserId: '',
        RoomId: '',
        ClientId: '',
        details: '',
        selectedDays: []
      },
      defaultEvent: {
        startDate: "",
        endDate: "",
        startHour: "",
        endHour: "",
        bulkStartDate: "",
        bulkEndDate: "",
        bulkStartHour: "",
        bulkEndHour: "",
        UserId: '',
        RoomId: '',
        ClientId: '',
        details: '',
        selectedDays: []
      },
      locked: true,
      lockUser: false,
      lockClient: false,
      processing: false,
      deletingEvent: false,
      loadingClients: false,
      clientType: ''
    };
  },
  methods: {
    saveStartDate(date) {
      if (this.event.endDate) {
        this.event.endDate = date;
      }
      this.$refs.toggleStartDate.save(date);
    },
    openClientInfo() {
      this.$refs.addEditClient.open(this.event.ClientId);
    },
    openClientInfoEmpty() {
      this.$refs.addEditClient.open();
    },
    openObs() {
      this.$refs.observations.open(_.find(this.clients, { 'id': this.event.ClientId }));
    },
    customFilter(item, queryText, itemText) {
      return true;
    },
    async myFn(newVal) {
      if (newVal) {
        this.loadingClients = true;
        this.clients = (await ServiceClients.listDebounce(newVal)).data;
        this.$refs.cbClient.$el.focus();
        this.loadingClients = false;
      }
    },
    async DeleteSelectedEvent() {
      try {

        let bulkDelete = false;
        if (this.event.linkedId) {
          if (await this.$refs.confirm.open("Confirmação",
            `Esta marcação faz parte de um conjunto de marcações periódicas.
            <br><br> Pretende eliminar todas as marcações deste conjunto?`, {
            color: "green lighten-1",
            width: 400,
            zIndex: 200,
            noconfirm: false,
            disagreeText: "Não, apenas esta",
            agreeText: "Sim, eliminar todas",
          })) {
            bulkDelete = true;
          }
        } else {

          if (await this.$refs.confirm.open("Confirmação",
            "Confirma a remoção deste elemento?", {
            color: "green lighten-1",
            width: 400,
            zIndex: 200,
            noconfirm: false,
            disagreeText: "Não",
            agreeText: "Sim",
          })) {
          } else {
            return;
          }
        }

        this.processing = true;

        if (bulkDelete) {
          const result = await ServiceCalendars.deletebulk(this.event.linkedId);
          this.$emit('handleDelete', result.data);

        } else {

          await ServiceCalendars.delete(this.event.id);

          this.$emit('handleDelete', this.event.id);
        }

        this.dialog = false;
        this.$store.dispatch("setSnackbar", {});
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: "Ocorreu um erro ao eliminar o evento"
        });

      }
      this.processing = false;
    },
    open(event, clientId, clientName) {
      // let query = _.clone(this.$route.query, true);
      // query.dialogSearch = true;
      // this.$router.push({
      //   query: query
      // });
      this.tab = 0;
      this.locked = true;
      this.lockUser = false;
      this.lockClient = false;
      this.clients = [];

      if (event) {
        this.oldEvent = event;
        this.showTab = false;
        this.clients.push({ id: event.ClientId, name: event.name });
        this.event.ClientId = event.ClientId;
        this.event.UserId = event.UserId;
        this.event.RoomId = event.RoomId;
        this.event.details = event.details;
        this.event.id = event.id;
        this.event.linkedId = event.linkedId;

        this.event.startDate = event.start.split(' ')[0];
        this.event.startHour = event.start.split(' ')[1];
        this.event.endDate = event.end.split(' ')[0];
        this.event.endHour = event.end.split(' ')[1];

      } else {
        this.showTab = true;
        this.event = Object.assign({}, this.defaultEvent);
        if (this.rooms.length == 1) {
          this.event.RoomId = this.rooms[0].id;
        }
      }

      if (clientId && clientName) {
        this.clients.push({ id: clientId, name: clientName });
        this.event.ClientId = clientId;
        this.lockClient = true;
      }

      if (this.$store.state.user.role === 'admin') {
        this.locked = false;
        this.lockUser = true;
      } else {
        if (!this.event.UserId) {
          this.event.UserId = this.$store.state.user.id;
        }

        if (this.$store.state.user.id === this.event.UserId) {
          this.locked = false;
        }
      }

      this.dialog = true;
    },
    FilterItems() {
      this.filteredList = _.filter(this.itemList, item => {
        return (
          item[this.description]
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) > -1
        );
      });
    },
    pickItem(item) {
      //   this.selectedItem = item[this.description];
      if (this.fullReturn) {
        this.$emit("selection", item);
      } else {
        this.$emit("selection", item[this.id]);
      }
      this.dialog = false;
    },
    addTime(time) {

      let currentDate;

      if (this.event.endDate && this.event.endHour) {
        currentDate = moment(this.event.endDate + 'T' + this.event.endHour);
      } else {
        currentDate = moment(this.event.startDate + 'T' + this.event.startHour);
      }

      currentDate = moment(currentDate)
        .add(time, "H")
        .format("YYYY-MM-DD HH:mm");

      this.event.endDate = currentDate.split(' ')[0];
      this.event.endHour = currentDate.split(' ')[1];

    },
    submitedData(item) {
      this.clients = [];
      this.clients.push(item);
      this.event.ClientId = item.id;
    },
    async createEventChooser() {
      if (this.tab === 0) {
        if (this.event.linkedId) {

          const oldStartDate = new Date(this.oldEvent.start);
          let weekDay = '';
          switch (oldStartDate.getDay()) {
            case 0:
              weekDay = 'Domingo';
              break;
            case 1:
              weekDay = 'Segunda-Feira';
              break;
            case 2:
              weekDay = 'Terça-Feira';
              break;
            case 3:
              weekDay = 'Quarta-Feira';
              break;
            case 4:
              weekDay = 'Quinta-Feira';
              break;
            case 5:
              weekDay = 'Sexta-Feira';
              break;
            case 6:
              weekDay = 'Sábado';
              break;
            default:
              break;
          }

          if (await this.$refs.confirm.open("Confirmação",
            `Esta marcação faz parte de um conjunto de marcações periódicas.<br><br> Pretende aplicar esta alteração a todas as marcações de ${weekDay} deste conjunto?`, {
            color: "green lighten-1",
            width: 400,
            zIndex: 200,
            noconfirm: false,
            disagreeText: "Não, apenas esta",
            agreeText: "Sim",
          })) {
            this.bulkEdit();
          } else {
            this.addNewEvent();
          }
        } else {
          this.addNewEvent();
        }
      } else {
        this.bulkCreateEvents();
      }
    },
    async sendSms() {
      try {
        this.processing = true;
        const results = await ServiceCalendars.sendMsg(
          this.event.id
        );
        this.processing = false;

        this.$store.dispatch("setSnackbar", {});

      } catch (error) {
        this.processing = false;
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }

    },
    async bulkEdit() {
      try {
        if (this.$refs.newEventForm.validate()) {
          this.processing = true;

          const oldStartDate = new Date(this.oldEvent.start);
          const oldEndDate = new Date(this.oldEvent.end);

          const newStartDate = new Date(this.event.startDate + " " + this.event.startHour);
          const newEndDate = new Date(this.event.endDate + " " + this.event.endHour);

          let startDiference = newStartDate.getTime() - oldStartDate.getTime();
          let endDiference = newEndDate.getTime() - oldEndDate.getTime();

          const results = await ServiceCalendars.bulkValidateForEdit(
            this.event.linkedId,
            this.event.RoomId,
            this.event.UserId,
            startDiference,
            endDiference,
            oldStartDate
          );

          if (results.data.busyEmployee.length > 0 && results.data.busyRoom.length > 0) {

            if (!await this.$refs.confirm.open("Confirmação",
              `Funcionário tem conflito de marcações nos dias:
                <br>${results.data.busyEmployee} 
                <br><br>Sala tem conflito de marcações nos dias:
                <br>${results.data.busyRoom} 
                <br><br>Prentende continuar com a operação?`, {
              color: "green lighten-1",
              width: 400,
              zIndex: 200,
              noconfirm: false,
              disagreeText: "Cancelar",
              agreeText: "Continuar",
            })) {
              this.processing = false;
              return;
            }

          }

          if (results.data.busyEmployee.length > 0 && results.data.busyRoom.length == 0) {
            if (!await this.$refs.confirm.open("Confirmação",
              `Funcionário tem conflito de marcações nos dias:
                <br>${results.data.busyEmployee} 
                <br><br>Prentende continuar com a operação?`, {
              color: "green lighten-1",
              width: 400,
              zIndex: 200,
              noconfirm: false,
              disagreeText: "Cancelar",
              agreeText: "Continuar",
            })) {
              this.processing = false;
              return;
            }

          }

          if (results.data.busyEmployee.length == 0 && results.data.busyRoom.length > 0) {
            if (!await this.$refs.confirm.open("Confirmação",
              `Sala tem conflito de marcações nos dias:
                <br>${results.data.busyRoom} 
                <br><br>Prentende continuar com a operação?`, {
              color: "green lighten-1",
              width: 400,
              zIndex: 200,
              noconfirm: false,
              disagreeText: "Cancelar",
              agreeText: "Continuar",
            })) {
              this.processing = false;
              return;
            }

          }

          const response = await ServiceCalendars.bulkEditEvent(this.event.linkedId, this.event.ClientId, this.event.RoomId, this.event.UserId,
            startDiference, endDiference, oldStartDate, this.event.details);

          this.processing = false;

          this.$emit('handleEvent', response.data);

          this.$store.dispatch("setSnackbar", {});

          this.dialog = false;

        }
      } catch (error) {
        this.processing = false;
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    async addNewEvent() {
      try {
        if (this.$refs.newEventForm.validate()) {
          this.processing = true;

          const results = await ServiceCalendars.validateNewEvent(
            this.event.UserId,
            this.event.RoomId,
            this.event.startDate + " " + this.event.startHour,
            this.event.endDate + " " + this.event.endHour,
            this.event.id
          );

          if (results.data.busyEmployee && results.data.busyRoom) {
            if (!await this.$refs.confirm.open("Confirmação",
              "Funcionário e Sala já têm marcações no intervalo de tempo seleccionado. Prentende continuar com a operação?", {
              color: "green lighten-1",
              width: 400,
              zIndex: 200,
              noconfirm: false,
              disagreeText: "Cancelar",
              agreeText: "Continuar",
            })) {
              this.processing = false;
              return;
            }
          }

          if (results.data.busyEmployee && !results.data.busyRoom) {
            if (!await this.$refs.confirm.open("Confirmação",
              "Funcionário já tem marcação no intervalo de tempo seleccionado. Prentende continuar com a operação?", {
              color: "green lighten-1",
              width: 400,
              zIndex: 200,
              noconfirm: false,
              disagreeText: "Cancelar",
              agreeText: "Continuar",
            })) {
              this.processing = false;
              return;
            }
          }

          if (!results.data.busyEmployee && results.data.busyRoom) {
            if (!await this.$refs.confirm.open("Confirmação",
              "Sala já tem marcação no intervalo de tempo seleccionado. Prentende continuar com a operação?", {
              color: "green lighten-1",
              width: 400,
              zIndex: 200,
              noconfirm: false,
              disagreeText: "Cancelar",
              agreeText: "Continuar",
            })) {
              this.processing = false;
              return;
            }

          }

          this.event.start = this.event.startDate + " " + this.event.startHour;
          this.event.end = this.event.endDate + " " + this.event.endHour;

          const response = await ServiceCalendars.addEvent(this.event);
          this.event.id = response.data.id;

          // if (_.find(this.users, ["id", this.selectedUser.id]).valid) {
          //   this.events = this.events.concat(newEvent.data);
          // }

          // this.fullEvents = this.fullEvents.concat(newEvent.data);

          this.processing = false;

          this.$emit('handleEvent', response.data);

          if (this.$store.state.user.settings.sendSms) {
            if (await this.$refs.confirm.open("Confirmação", "Operação efectuada com sucesso.<br>Deseja enviar SMS de confirmação?")) {
              await this.sendSms();
            }
          } else {
            this.$store.dispatch("setSnackbar", {});
          }

          this.dialog = false;

        }
      } catch (error) {
        this.processing = false;
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    async bulkCreateEvents() {
      try {

        let hasError = false;
        if (this.event.selectedDays.length == 0) {
          this.showDayError = true;
          hasError = true;
        } else {
          this.showDayError = false;
        }

        if (!this.$refs.newEventForm.validate()) {
          hasError = true;
        }

        if (hasError) {
          return;
        }

        this.processing = true;

        const results = await ServiceCalendars.bulkValidate(
          this.event.UserId,
          this.event.RoomId,
          this.event.bulkStartDate,
          this.event.bulkEndDate,
          this.event.bulkStartHour,
          this.event.bulkEndHour,
          this.event.selectedDays
        );

        if (results.data.busyEmployee.length > 0 && results.data.busyRoom.length > 0) {
          if (!await this.$refs.confirm.open("Confirmação",
            `Funcionário tem conflito de marcações nos dias:
              <br>${results.data.busyEmployee} 
                <br><br>Sala tem conflito de marcações nos dias:
                <br>${results.data.busyRoom} 
                <br><br>Prentende continuar com a operação?`, {
            color: "green lighten-1",
            width: 400,
            zIndex: 200,
            noconfirm: false,
            disagreeText: "Cancelar",
            agreeText: "Continuar",
          })) {
            this.processing = false;
            return;
          }

        }

        if (results.data.busyEmployee.length > 0 && results.data.busyRoom.length == 0) {
          if (!await this.$refs.confirm.open("Confirmação",
            `Funcionário tem conflito de marcações nos dias:
              <br>${results.data.busyEmployee} 
                <br><br>Prentende continuar com a operação?`, {
            color: "green lighten-1",
            width: 400,
            zIndex: 200,
            noconfirm: false,
            disagreeText: "Cancelar",
            agreeText: "Continuar",
          })) {
            this.processing = false;
            return;
          }

        }

        if (results.data.busyEmployee.length == 0 && results.data.busyRoom.length > 0) {
          if (!await this.$refs.confirm.open("Confirmação",
            `Sala tem conflito de marcações nos dias:
                <br>${results.data.busyRoom} 
                <br><br>Prentende continuar com a operação?`, {
            color: "green lighten-1",
            width: 400,
            zIndex: 200,
            noconfirm: false,
            disagreeText: "Cancelar",
            agreeText: "Continuar",
          })) {
            this.processing = false;
            return;
          }
        }

        const response = await ServiceCalendars.bulkAddEvent(this.event.ClientId, this.event.RoomId, this.event.UserId,
          this.event.bulkStartDate, this.event.bulkEndDate, this.event.bulkStartHour, this.event.bulkEndHour, this.event.selectedDays, this.event.details);

        this.processing = false;

        this.$store.dispatch("setSnackbar", {});

        this.$emit('handleEvent', response.data);

        this.dialog = false;

      } catch (error) {
        this.processing = false;
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    close() {
      this.dialog = false;
    },
    navGuards() {
      if (this.$refs.addEditClient) {
        if (this.$refs.addEditClient.show) {
          this.$refs.addEditClient.show = false;
          return true;
        }
      }

      if (this.$refs.observations) {
        if (this.$refs.observations.editDialog) {
          this.$refs.observations.editDialog = false;
          return true;
        }

        if (this.$refs.observations.show) {
          this.$refs.observations.show = false;
          return true;
        }
      }


      if (this.toggleStartDate || this.toggleEndDate || this.toggleStartHour || this.toggleEndHour || this.bulkToggleStartDate || this.bulkToggleEndDate || this.bulkToggleStartHour || this.bulkToggleEndHour) {
        this.toggleStartDate = this.toggleEndDate = this.toggleStartHour = this.toggleEndHour = this.bulkToggleStartDate = this.bulkToggleEndDate = this.bulkToggleStartHour = this.bulkToggleEndHour = false;
        return true;
      }

      if (this.dialog) {
        this.dialog = false;
        return true;
      }

    }
  },
  created() {

    this.clientType = config.CLIENT_TYPE_SINGULAR;
    const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {

      // if (this.$refs.addEditClient) {
      //   if (this.$refs.addEditClient.show) {
      //     this.$refs.addEditClient.show = false;
      //     next(false);
      //     return;
      //   }
      // }

      // if (this.$refs.observations) {
      //   if (this.$refs.observations.editDialog) {
      //     this.$refs.observations.editDialog = false;
      //     next(false);
      //     return;
      //   }

      //   if (this.$refs.observations.show) {
      //     this.$refs.observations.show = false;
      //     next(false);
      //     return;
      //   }
      // }


      // if (this.toggleStartDate || this.toggleEndDate || this.toggleStartHour || this.toggleEndHour || this.bulkToggleStartDate || this.bulkToggleEndDate || this.bulkToggleStartHour || this.bulkToggleEndHour) {
      //   this.toggleStartDate = this.toggleEndDate = this.toggleStartHour = this.toggleEndHour = this.bulkToggleStartDate = this.bulkToggleEndDate = this.bulkToggleStartHour = this.bulkToggleEndHour = false;
      //   next(false);
      //   return;
      // }

      // if (this.dialog) {
      //   this.dialog = false;
      //   next(false);
      //   return;
      // }

      if (this.navGuards()) {
        next(false);
        return;
      }

      next();
    });

    this.$once('hook:destroyed', () => {
      unregisterRouterGuard()
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
