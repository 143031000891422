import Api from '@/services/ServiceBase'
const path = 'settings'

export default {
  update(item) {
    return Api().post(path, item)
  },
  show(name) {
    return Api().get(path + `/${name}`)
  }
}
