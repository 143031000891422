<template>
  <div>
    <v-speed-dial fab fixed v-model="fab" :bottom="true" :right="true" direction="top" :open-on-hover="false">
      <template v-slot:activator>
        <v-btn v-model="fab" color="blue darken-2" dark large fab>
          <v-icon v-if="fab">mdi-close</v-icon>
          <v-icon v-else>mdi-filter-variant</v-icon>
        </v-btn>
      </template>
      <v-btn fab dark large color="green" @click.stop="filterDialog = true">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <v-btn fab dark large color="indigo" @click="addEditItem">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-speed-dial>
    <v-container style="margin-bottom: 70px;">
      <v-row align="center" justify="center">
        <v-col cols="12" md="10">
          <v-data-table v-infinite-scroll="getClients" infinite-scroll-disabled="busy" infinite-scroll-distance="200"
            :headers="headers" :items="clients" :loading="loading" hide-default-footer
            :items-per-page="clients.length + 10" class="mTable">
            <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>
            <template v-slot:item.discontinued="{ item }">
              <v-icon v-if="item.discontinued" color="red darken-2">mdi-close</v-icon>
              <v-icon v-else color="green darken-2">mdi-check</v-icon>
            </template>
            <template v-slot:item.action="{ item }">

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" @click="addEditItem(item)">
                    <v-icon v-on="on" color="teal">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" @click="openObservations(item)">
                    <v-icon v-on="on" color="light-blue">mdi-information-outline</v-icon>
                  </v-btn>
                </template>
                <span>Observações</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" @click="openAppointments(item)">
                    <v-icon v-on="on" color="light-blue">mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <span>Marcações</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" @click="deleteItem(item)">
                    <v-icon v-on="on" color="pink">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Eliminar</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <!-- <v-dialog v-model="editDialog" persistent fullscreen hide-overlay scrollable transition="dialog-bottom-transition">
      <add-edit-client ref="qwerty" :client="editedItem" @submited="submitedData" @close="close" />
    </v-dialog> -->

    <add-edit-client ref="addEditClient" @submited="submitedData" />
    <observations ref="observations" />
    <appointments ref="appointments" :rooms="rooms" :users="users" />

    <v-dialog v-model="filterDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">Filtros :</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field label="Nome" clearable v-model="name"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field label="Nif" clearable v-model="nif"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field label="Email" clearable v-model="email"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field label="Telefone" clearable v-model="phone"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-radio-group v-model="discontinued" column>
                  <v-radio v-for="item in items" :key="item.value" :label="item.text" :value="item.value"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="blue darken-1" text @click="filterDialog = false">Fechar</v-btn>
          <v-btn color="blue darken-1" text @click="Filter()">Procurar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import infiniteScroll from "vue-infinite-scroll";
import ServiceClients from "@/services/ServiceClients";
import AddEditClient from "@/components/common/AddEditClient.vue";
import Observations from "@/components/client/Observations.vue";
import Appointments from "@/components/client/ClientAppointments";
import { getUsersAndRooms } from "@/components/Utils";
export default {
  name: "List-Clients",
  directives: {
    infiniteScroll
  },
  components: {
    AddEditClient,
    Observations,
    Appointments
  },
  data() {
    return {
      users: [],
      rooms: [],
      editDialog: false,
      editedIndex: -1,
      editedItem: {
      },
      defaultItem: {
      },

      filterDialog: false,
      fab: false,
      page: 1,
      headers: [
        { text: "Nome", sortable: false, value: "name" },
        { text: "Activo", sortable: false, value: "discontinued", align: "right" },
        { text: "", value: "action", sortable: false, align: "right" }
      ],
      clients: [],
      busy: false,
      loading: true,
      name: null,
      nif: null,
      email: null,
      phone: null,
      externalID: null,
      discontinued: 2,
      items: [
        { text: "Ver todos", value: 2 },
        { text: "Ver apenas activos", value: 0 },
        { text: "Ver apenas inactivos", value: 1 }
      ]
    };
  },
  methods: {
    openClientInfo(id) {
      this.$refs.addEditClient.open(id);
    },
    async getClients() {
      try {
        this.busy = true;
        this.loading = true;
        const response = await ServiceClients.list(
          this.name,
          this.page,
          this.discontinued,
          this.nif,
          this.email,
          this.phone,
          this.externalID
        );
        response.data.forEach(element => {
          this.clients.push(element);
        });
        this.page++;
        this.busy = false;
        if (response.data.length == 0) {
          this.busy = true;
        }

      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
      this.loading = false;
    },
    async deleteItem(item) {
      if (confirm("Confirma a eliminação deste elemento?")) {
        try {
          const index = this.clients.indexOf(item);
          await ServiceClients.delete(item.id);
          this.clients.splice(index, 1);
          this.$store.dispatch("setSnackbar", {});
        } catch (error) {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: error
          });
        }
      }
    },
    submitedData(item) {
      if (this.editedIndex > -1) {
        Object.assign(this.clients[this.editedIndex], item);
      } else {
        this.clients.unshift(item);
      }
    },
    openObservations(item) {
      this.$refs.observations.open(item);
    },
    openAppointments(item) {
      this.$refs.appointments.open(item);
    },
    addEditItem(item) {
      try {
        if (item) {
          this.editedIndex = this.clients.indexOf(item);
          this.editedItem = Object.assign({}, item);
        } else {
          this.editedIndex = -1;
          this.editedItem = Object.assign({}, this.defaultItem);
        }
        this.$refs.addEditClient.open(this.editedItem.id);
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    close() {
      this.editDialog = false;
    },
    Filter() {
      this.clients = [];
      this.page = 1;
      this.filterDialog = false;
      this.getClients();
    }
  },
  async mounted() {
    try {
      await getUsersAndRooms(1, 1, this.users, this.rooms);
    } catch (error) {
      this.$store.dispatch("setSnackbar", {
        color: "error",
        text: error
      });
    }
  },
  created() {

    const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {

      if (this.$refs.addEditClient) {
        if (this.$refs.addEditClient.show) {
          this.$refs.addEditClient.show = false;
          next(false);
          return;
        }
      }

      if (this.$refs.appointments) {

        if (this.$refs.appointments.$refs.eventDialog) {
          if (this.$refs.appointments.$refs.eventDialog.dialog) {
            if (this.$refs.appointments.$refs.eventDialog.navGuards()) {
              next(false);
              return;
            }
          }
        }

        if (this.$refs.appointments.show) {
          this.$refs.appointments.show = false;
          next(false);
          return;
        }
      }

      if (this.$refs.observations) {
        if (this.$refs.observations.editDialog) {
          this.$refs.observations.editDialog = false;
          next(false);
          return;
        }

        if (this.$refs.observations.show) {
          this.$refs.observations.show = false;
          next(false);
          return;
        }
      }

      if (this.filterDialog) {
        this.filterDialog = false;
        next(false);
        return;
      }

      next();
    });

    this.$once('hook:destroyed', () => {
      unregisterRouterGuard()
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
