<template>
  <v-dialog v-model="dialog" persistent width="450px" hide-overlay transition="dialog-bottom-transition">
    <!-- <v-btn flat slot ="activator" class="success">Open sesame</v-btn> -->

    <template v-slot:activator="{ on }">
      <v-text-field slot="activator" :label="label" @click="start(on)" :disabled="disabled" :rules="rules" v-model="selectedItem"></v-text-field>
    </template>
    <v-card height="600px">
      <v-toolbar dark color="primary" fixed>
        <v-toolbar-title>{{title}}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon dark @click="dialog = !dialog">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text style="padding-top: 15px;">
        <v-text-field
          flat
          hide-details
          @input="FilterItems()"
          v-model="search"
          prepend-inner-icon="search"
          label="Procurar"
        ></v-text-field>
        <div  style="height:433px;overflow-y: scroll;">
        <v-container grid-list-md>
          <v-layout wrap>
            <v-flex v-for="item in filteredList" :key="item[id]" xs12>
              <v-card @click="pickItem(item)" class="d-flex align-center" style="cursor: pointer;">
                <v-card-text>
                  <p style="word-break: break-word;">{{item[description]}}</p>
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from "lodash";

export default {
  name: "HelloWorld",
  props: ["itemList", "description", "id", "label", "title", "fullReturn", "selectedItem", "disabled","rules"],
  data() {
    return {
      dialog: false,
      search: "",
      filteredList: [],
    };
  },
  methods: {
    start(on) {
      this.filteredList = this.itemList;
      this.dialog = true;
    },
    FilterItems() {
      this.filteredList = _.filter(this.itemList, item => {
        return (
          item[this.description]
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) > -1
        );
      });
    },
    pickItem(item) {
    //   this.selectedItem = item[this.description];
      if (this.fullReturn) {
        this.$emit("selection", item);
      } else {
        this.$emit("selection", item[this.id]);
      }
      this.dialog = false;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.fullWidth {
  width: 100%;
}
.spacer {
  height: 70px;
}
</style>
