var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-speed-dial',{attrs:{"fab":"","fixed":"","bottom":true,"right":true,"direction":"top","open-on-hover":false},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-btn',{attrs:{"color":"blue darken-2","dark":"","large":"","fab":""},model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[(_vm.fab)?_c('v-icon',[_vm._v("mdi-close")]):_c('v-icon',[_vm._v("mdi-filter-variant")])],1)]},proxy:true}]),model:{value:(_vm.fab),callback:function ($$v) {_vm.fab=$$v},expression:"fab"}},[_c('v-btn',{attrs:{"fab":"","dark":"","large":"","color":"green"},on:{"click":function($event){$event.stopPropagation();_vm.filterDialog = true}}},[_c('v-icon',[_vm._v("mdi-magnify")])],1),_c('v-btn',{attrs:{"fab":"","dark":"","large":"","color":"indigo"},on:{"click":function($event){return _vm.addEditItem()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('v-data-table',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.getUsers),expression:"getUsers"}],staticClass:"mTable",attrs:{"id":"mytable","infinite-scroll-disabled":"busy","infinite-scroll-distance":"10","headers":_vm.headers,"items":_vm.users,"loading":_vm.loading,"hide-default-footer":"","items-per-page":_vm.users.length + 10},scopedSlots:_vm._u([{key:"item.color",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"pa-5",attrs:{"color":item.color}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"justify-center layout px-0"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.addEditItem(item)}}},[_c('v-icon',_vm._g({attrs:{"color":"teal"}},on),[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{staticClass:"mx-0",attrs:{"icon":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',_vm._g({attrs:{"color":"pink"}},on),[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])])],1)]}}],null,true)},[_c('v-progress-linear',{attrs:{"slot":"progress","color":"blue","indeterminate":""},slot:"progress"})],1)],1)],1),_c('v-dialog',{ref:"test",attrs:{"persistent":"","scrollable":"","width":"600","transition":"dialog-bottom-transition"},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('add-edit-user',{ref:"qwerty",attrs:{"room":_vm.editedItem},on:{"submited":_vm.submitedData,"close":_vm.close}})],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},model:{value:(_vm.filterDialog),callback:function ($$v) {_vm.filterDialog=$$v},expression:"filterDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Filtros :")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.filterDialog = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Nome","clearable":""},model:{value:(_vm.userName),callback:function ($$v) {_vm.userName=$$v},expression:"userName"}})],1)],1)],1)],1),_c('v-card-actions',[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.Filter()}}},[_vm._v("Procurar")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }