<template>
  <v-container>

    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card>
          <img src="@/assets/stop.png" style="width:85%;height:85%;" alt="view">

          <v-card-title primary-title>
            <div>
              <h3 class="headline mb-0">Não tem premissões para aceder a este recurso.</h3>
            </div>
          </v-card-title>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>

export default {
  name: 'HelloWorld',
  data() {
    return {
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
