<template>
  <v-dialog v-model="show" scrollable width="600" transition="dialog-bottom-transition">
    <v-card>
      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
      <v-card-title class="pa-0">
        <v-toolbar dark color="primary">
          <v-toolbar-title>{{ client.id ? client_type_edit : client_type_add }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="show = !show">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-container>
            <v-row align="center" justify="center">
              <v-col cols="12">
                <v-text-field v-model="client.name" :rules="[rules.required, rules.counter]" :counter="250" label="Nome"
                  required></v-text-field>
              </v-col>
            </v-row>

            <v-row align="center" justify="center">
              <v-col cols="12">
                <v-textarea v-model="client.address" label="Morada"></v-textarea>
              </v-col>
            </v-row>

            <v-row align="center" justify="center">
              <v-col cols="12">
                <v-text-field v-model="client.nif" label="NIF"></v-text-field>
              </v-col>
            </v-row>

            <v-row align="center" justify="center">
              <v-col cols="12">
                <v-text-field v-model="client.phone" label="Telefone"></v-text-field>
              </v-col>
            </v-row>

            <v-row align="center" justify="center">
              <v-col cols="12">
                <v-text-field v-model="client.email" label="E-mail"></v-text-field>
              </v-col>
            </v-row>

            <v-row align="center" justify="center">
              <v-col cols="12">
                <v-text-field v-model="client.occupation" label="Profissão"></v-text-field>
              </v-col>
            </v-row>

            <v-row align="center" justify="center">
              <v-col cols="12">
                <v-dialog ref="dialog" v-model="modal" :return-value.sync="client.birthDate" persistent width="290px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field v-model="client.birthDate" label="Data de Nascimento" readonly v-bind="attrs"
                      v-on="on"></v-text-field>
                  </template>
                  <v-date-picker v-model="client.birthDate" scrollable>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal = false">
                      Cancelar
                    </v-btn>
                    <v-btn text color="primary" @click="$refs.dialog.save(client.birthDate)">
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-dialog>

              </v-col>
            </v-row>

            <v-row align="center" justify="center">
              <v-col cols="12">
                <v-textarea v-model="client.observations" label="Observações"></v-textarea>
              </v-col>
            </v-row>

            <!-- <v-row align="center" justify="center">
        <v-col cols="12">
          <v-text-field v-model="client.externalID" label="ExternalID"></v-text-field>
        </v-col>
      </v-row> -->

            <v-row align="center" justify="center">
              <v-col cols="12">
                <v-checkbox v-model="client.discontinued" label="Inactivo"></v-checkbox>
              </v-col>
            </v-row>


            <!-- <v-row align="center" justify="center">
        <v-col cols="12">
          <v-card-actions>
            <v-btn text color="red" outlined @click="clear">Limpar</v-btn>
            <v-spacer></v-spacer>
            <v-btn text color="primary" outlined :loading="loading" :disabled="!valid" @click="submit">Submeter</v-btn>
          </v-card-actions>
        </v-col>
      </v-row> -->
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn text color="red" outlined @click="clear">Limpar</v-btn>
        <v-spacer></v-spacer>
        <v-btn text color="primary" outlined @click="submit">Submeter</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ServiceClients from "@/services/ServiceClients";
// import backButton from '@/mixins/backButton';
export default {
  name: "AddEditClient",
  // mixins: [
  //   backButton(['show'])
  // ],
  data() {
    return {
      modal: false,
      show: false,
      client: {},
      editDialog: false,
      loading: false,
      valid: true,
      client_type_add: '',
      client_type_edit: '',
      rules: {
        required: value => !!value || "Campo Obrigatório.",
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || 'E-mail inválido.';
        },
        counter: value =>
          (value && value.length <= 250) ||
          "Campo tem de ter menos de 250 caracteres",
        isValidNumber: value =>
          /^([0-9]+[.])?[0-9]+$/.test(value) || "Valor inválido"
      }
    };
  },
  methods: {
    async open(id) {
      this.show = true;
      this.client = {};
      this.valid = true;

      if (id) {
        try {
          this.loading = true;
          this.client = (await ServiceClients.show(id)).data;

        } catch (error) {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: error
          });
        }
      }
      this.loading = false;
    },
    async submit() {
      try {
        if (this.$refs.form.validate()) {
          let response;
          this.loading = true;

          // if (this.client.Id) {
          //   response = await ServiceClients.put(this.client);
          // } else {
          //   response = await ServiceClients.add(this.client);
          //   this.$refs.form.reset();
          // }

          response = await ServiceClients.add(this.client);

          this.$emit('submited', response.data);

          this.$store.dispatch("setSnackbar", {});
          this.show = false;
        }
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }

      this.loading = false;
    },
    clear() {
      this.$refs.form.reset();
    },
    close() {
      this.$emit('close');
    }
  },
  mounted() {
    this.client_type_add = "Adicionar " + config.CLIENT_TYPE_SINGULAR;
    this.client_type_edit = "Editar " + config.CLIENT_TYPE_SINGULAR;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
