import Vue from 'vue'
import Router from 'vue-router'
import ListClient from '@/components/client/ListClient'
import ListUsers from '@/components/user/ListUsers'
import EditPass from '@/components/user/EditPass'
import CalendarPeopleOverview from '@/components/calendars/CalendarPeopleOverview'
// import Calendar from '@/components/calendar/Calendar'
import CalendarFull from '@/components/calendars/CalendarFull'
import ListRooms from '@/components/room/ListRooms'
import Login from '@/components/Login'
import Denied from '@/components/Denied'
import Home from '@/components/Home'
import Settings from '@/components/settings/Settings'
import store from '@/store/store'

Vue.use(Router)

export let router = new Router({
  routes: [{
      path: '/home',
      name: 'home',
      component: Home,
      meta: {
        auth: true,
        title: 'O meu Calendário'
      }
    },
    {
      path: '/',
      name: 'empty-path',
      component: Home,
      meta: {
        auth: true,
        title: 'O meu Calendário'
      }
    },
    {
      path: '/settings',
      name: 'settings',
      component: Settings,
      meta: {
        auth: true,
        title: 'Configurações'
      }
    },
    {
      path: '/clients',
      name: 'clients',
      component: ListClient,
      meta: {
        auth: true,
        title: 'client_type'
      }
    },
    {
      path: '/clients/:clientid',
      name: 'clients-detail',
      component: ListClient,
      meta: {
        auth: true
      }
    },
    {
      path: '/users',
      name: 'users',
      component: ListUsers,
      meta: {
        auth: true,
        title: 'Funcionários'
      }
    },
    {
      path: '/edit-pass',
      name: 'edit-pass',
      component: EditPass,
      meta: {
        auth: true,
        title: 'Editar Password'
      }
    },
    {
      path: '/login',
      name: 'login',
      component: Login
    },
    {
      path: '/denied',
      name: 'denied',
      component: Denied
    },
    {
      path: '/users/:userid',
      name: 'users-detail',
      component: ListUsers,
      meta: {
        auth: true,
        title: 'Funcionários'
      }
    },
    {
      path: '/CalendarPeopleOverview',
      name: 'calendarPeopleOverview',
      component: CalendarPeopleOverview,
      meta: {
        auth: true,
        title: 'Calendário Funcionários'
      }
    },
    {
      path: '/CalendarFull',
      name: 'calendarFull',
      component: CalendarFull,
      meta: {
        auth: true,
        title: 'Calendário Completo'
      }
    },
    {
      path: '/rooms',
      name: 'rooms',
      component: ListRooms,
      meta: {
        auth: true,
        title: 'Salas'
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.meta.auth && !store.state.isUserLoggedIn) {
    next({
      path: '/login',
      query: {
        redirect: to.fullPath
      }
    });
  } else {
    if (to.meta.auth && store.state.logTime == null) {
      next({
        path: '/login',
        query: {
          redirect: to.fullPath
        }
      });
    } else {
      if (to.meta.auth && (Date.now() - store.state.logTime >= 43200000)) {
        next({
          path: '/login',
          query: {
            redirect: to.fullPath
          }
        });
      } else {
        next();
      }
    }
  }
})
