<template>
    <v-layout justify-center>
      <v-container>
        <v-layout align-center justify-center>
          <v-flex xs12 sm12 md8>
            <v-card>
              <v-toolbar dark color="primary">
                <v-toolbar-title>Alterar Password</v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-text>
                <v-form ref="form" v-model="valid">
                  <v-text-field
                    :rules="[rules.required,rules.counter]"
                    id="password"
                    prepend-icon="mdi-lock"
                    name="password"
                    label="Password"
                    type="password"
                    :counter="25"
                    v-model="password"
                  ></v-text-field>
                  <v-text-field
                    :rules="[rules.required,rules.counter]"
                    id="confirmPassword"
                    prepend-icon="mdi-lock"
                    name="confirmPassword"
                    label="Confirmação Password"
                    type="password"
                    :counter="25"
                    v-model="confirmPass"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" :loading="loading" @click="updatePass()">Confirmar</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
      <v-overlay :value="loading">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-layout>
  </template>
  
  <script>
  import ServiceUsers from "@/services/ServiceUsers";
  export default {
    name: "Password-Edit",
    data: () => ({
      valid: true,
      loading: false,
      password: "",
      confirmPass: "",
      rules: {
        required: value => !!value || "Campo Obrigatório.",
        counter: value =>
          (value && value.length >= 4) ||
          "Este campo tem de ter pelo menos 4 caracteres"
      }
    }),
    methods: {
      async updatePass() {
        try {
          if (this.$refs.form.validate()) {
            this.loading = true;
  
            if (this.password === this.confirmPass) {
              await ServiceUsers.changePassword(this.password);
  
              this.$refs.form.reset();
  
              this.$store.dispatch("setSnackbar", {});
            } else {
              this.$store.dispatch("setSnackbar", {
                color: "error",
                text: "Password e Confirmação têm de ser iguais."
              });
            }
  
            this.loading = false;
          }
        } catch (error) {
          this.loading = false;
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: error
          });
        }
      }
    },
    async mounted() {
    }
  };
  </script>
  <style>
  </style>
  