<template>
  <v-row class="fill-height">
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-btn fixed dark fab bottom right large color="indigo" @click.stop="$refs.eventDialog.open()">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-col>
      <v-sheet height="64">
        <v-toolbar flat>
          <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
            Hoje
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ auxCapitalize($refs.calendar.title) }}
          </v-toolbar-title>

          <v-icon color="primary" @click="modal = true">mdi-calendar</v-icon>

          <v-dialog ref="dialog" v-model="modal" persistent width="290px">
            <v-date-picker v-model="auxFocus" scrollable>
              <v-btn text color="error" @click="modal = false">
                Cancelar
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn text color="success" @click="saveDate()">
                Confirmar
              </v-btn>
            </v-date-picker>
          </v-dialog>

          <v-spacer></v-spacer>

          <v-menu right>
            <template v-slot:activator="{ on }">
              <v-btn outlined color="grey darken-2" v-on="on">
                <span>{{ typeToLabel[type] }}</span>
                <v-icon right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="type = 'month'">
                <v-list-item-title>Mês</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'week'">
                <v-list-item-title>Semana</v-list-item-title>
              </v-list-item>
              <v-list-item @click="type = 'day'">
                <v-list-item-title>Dia</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>

          <v-menu>
            <template v-slot:activator="{ attrs, on }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on" outlined>
                Legenda
                <v-icon right dark>
                  mdi-home
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in rooms" :key="index">
                <v-list-item-avatar>
                  <v-chip class="pa-4 rounded-circle" :color="item.color">
                  </v-chip>
                </v-list-item-avatar>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet class="calHeight">
        <v-calendar ref="calendar" interval-width="60" locale="pt-PT" v-model="focus" color="primary" category-text="name"
          :events="events" :first-time=$config.Calendar_First_Time :interval-count=$config.Calendar_Interval_Count
          :interval-minutes=$config.Calendar_Interval_Minutes :type="type" @change="getCalendar"
          @click:event="showEvent"></v-calendar>
      </v-sheet>
    </v-col>
    <event-dialog ref="eventDialog" :rooms="rooms" :users="users" @handleEvent="handleEvent"
      @handleDelete="handleDelete"></event-dialog>
  </v-row>
</template>

<script>
import ServiceCalendars from "@/services/ServiceCalendars";
import EventDialog from "@/components/common/EventDialog.vue";
import _ from "lodash";
import { getUsersAndRooms, capitalize } from "@/components/Utils";
import moment from "moment";

export default {
  name: "Home",
  components: {
    EventDialog
  },
  data() {
    return {

      typeToLabel: {
        month: "Mês",
        week: "Semana",
        day: "Dia"
      },

      type: "week",
      types: [
        { text: "Mês", value: "month" },
        { text: "Semana", value: "week" },
        { text: "Dia", value: "day" }
      ],


      loading: false,
      focus: '',
      auxFocus: '',
      events: [],
      users: [],
      rooms: [],

      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10),
      modal: false
    };
  },
  methods: {
    auxCapitalize(word) {
      return capitalize(word);
    },
    saveDate() {
      this.focus = this.auxFocus;
      this.modal = false;
    },
    setToday() {
      this.focus = ''
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    showEvent({ nativeEvent, event }) {
      this.$refs.eventDialog.open(event);

      nativeEvent.stopPropagation();
    },
    handleDelete(id) {
      
      let mIds = [];
      mIds = mIds.concat(id);

      mIds.forEach(id => {
        let index = -1;
        index = _.findIndex(this.events, { id });
        if (index != -1) this.events.splice(index, 1);
      });
    },
    handleEvent(events) {
      
      let mEvents = [];
      mEvents = mEvents.concat(events);
      mEvents.forEach(event => {
        if (event.UserId === this.$store.state.user.id) {
          event.color = event.roomColor;

          const index = _.findIndex(this.events, { 'id': event.id });

          if (index === -1) {
            this.events.push(event);
          } else {
            this.events.splice(index, 1, event);
          }
        }
      });
    },
    async getCalendar() {
      try {

        if (this.users.length == 0) {
          return;
        }

        this.loading = true;
        this.events = [];
        // var date = new Date(this.$refs.calendar.rt.date);

        // let startDate = date.toISOString().slice(0, 10);
        // date.setDate(date.getDate() + 1);
        // let endDate = date.toISOString().slice(0, 10);

        const lastEnd = moment(this.$refs.calendar.lastEnd.date)
          .add(1, "days")
          .format("YYYY-MM-DD HH:mm");


        const response = await ServiceCalendars.getCalendar(
          this.$store.state.user.id,
          this.$refs.calendar.lastStart.date,
          lastEnd
        );


        _.forEach(response.data, value => {
          value.color = value.roomColor;
          this.events.push(value);
        });

        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
  },
  async mounted() {
    try {
      this.loading = true;
      await getUsersAndRooms(1, 1, this.users, this.rooms);

      this.getCalendar();
    } catch (error) {
      this.loading = false;
      this.$store.dispatch("setSnackbar", {
        color: "error",
        text: error
      });

    }
  },

  // watch: {
  //   $route(newVal) {

  //     // if (this.$refs.eventDialog.dialog) {
  //     //   console.log("qwewqe");
  //     //   this.$refs.eventDialog.close();
  //     //   return;
  //     // } else {
  //     //   this.$router.go(1);
  //     // }

  //   }
  // },
  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.calHeight {
  height: -moz-calc(100% - 64px);
  height: -webkit-calc(100% - 64px);
  height: -o-calc(100% - 64px);
  height: calc(100% - 64px);
}
</style>
