<template>
  <v-card>
    <v-card-title class="pa-0">
      <v-toolbar dark color="primary">        
        <v-toolbar-title>{{ room.id == -1 ? 'Adicionar' : 'Editar' }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-container>
          <v-row align="center" justify="center">
            <v-col cols="12">
              <v-text-field v-model="room.name" :rules="[rules.required, rules.counter]" :counter="250"
                label="Descrição" required></v-text-field>
            </v-col>
          </v-row>

          <v-row align="center" justify="center">
            <v-col cols="12" align="center" justify="center">
              <v-color-picker v-model="room.color" hide-inputs mode="hex" hide-mode-switch swatches-max-height="250"></v-color-picker>

            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn outlined text color="red" @click="clear">Limpar</v-btn>
      <v-spacer></v-spacer>
      <v-btn outlined text color="primary" :disabled="!valid" @click="submit">Submeter</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ServiceRooms from "@/services/ServiceRooms";
export default {
  name: "AddEdit-Room",
  props: ['room'],
  data() {
    return {
      valid: true,
      rules: {
        required: value => !!value || "Campo Obrigatório.",
        counter: value =>
          (value && value.length <= 250) ||
          "Campo tem de ter menos de 250 caracteres",
        isValidNumber: value =>
          /^([0-9]+[.])?[0-9]+$/.test(value) || "Valor inválido"
      }
    };
  },
  methods: {
    async submit() {
      try {
        if (this.$refs.form.validate()) {

          this.loading = true;
          const response = await ServiceRooms.createUpdate(this.room);

          if (!this.room.id) {
            this.$refs.form.reset();
          }

          this.$emit('submited', response.data);

          this.loading = false;
          this.$store.dispatch("setSnackbar", {});
        }
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    clear() {
      this.$refs.form.reset();
    },
    close() {
      this.$emit('close');
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
