var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"inspire"}},[(_vm.$route.path != '/login')?_c('v-navigation-drawer',{staticStyle:{"background-color":"#0D2149","padding-top":"50px"},attrs:{"dark":"","app":"","mini-variant":_vm.mini},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-list-item',{staticClass:"hidden-md-and-down",staticStyle:{"justify-content":"end"},on:{"click":function($event){$event.stopPropagation();_vm.mini = !_vm.mini}}},[(_vm.mini)?_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-chevron-right")]):_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-chevron-left")])],1)]},proxy:true}],null,false,2855433931),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"to":{
        name: 'home'
      }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-home")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Ínicio")])],1)],1),_c('v-divider',{staticClass:"mDivider"}),_c('v-list-item',{attrs:{"to":{
        name: 'clients'
      }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-tie")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.client_type))])],1)],1),(_vm.$store.state.user.role === 'admin')?_c('v-list-item',{attrs:{"to":{
        name: 'users'
      }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Funcionários")])],1)],1):_vm._e(),(_vm.$store.state.user.role === 'admin')?_c('v-list-item',{attrs:{"to":{
        name: 'rooms'
      }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-home-outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Salas")])],1)],1):_vm._e(),_c('v-divider',{staticClass:"mDivider"}),_c('v-list-item',{attrs:{"to":{
        name: 'calendarFull'
      }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-calendar")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Calendário Completo")])],1)],1),_c('v-list-item',{attrs:{"to":{
        name: 'calendarPeopleOverview'
      }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-calendar")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Calendário Funcionários")])],1)],1),_c('v-divider',{staticClass:"mDivider"}),_c('v-list-item',{attrs:{"to":{
        name: 'edit-pass'
      }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-lock")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Password")])],1)],1),_c('v-divider',{staticClass:"mDivider"}),(!_vm.$store.state.isUserLoggedIn)?_c('v-list-item',{attrs:{"to":{
        name: 'login'
      }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-logout-variant")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Login")])],1)],1):_vm._e(),(_vm.$store.state.isUserLoggedIn)?_c('v-list-item',{on:{"click":function($event){return _vm.logout()}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-logout-variant")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Logout")])],1)],1):_vm._e()],1)],1):_vm._e(),_c('v-app-bar',{staticClass:"elevation-0",staticStyle:{"background-color":"#f5f5ff"},attrs:{"app":""}},[_c('v-toolbar-title',{staticClass:"ml-0 pl-4",staticStyle:{"width":"300px"}},[(_vm.$route.path != '/login')?_c('v-app-bar-nav-icon',{staticClass:"hidden-lg-and-up",on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}},[_c('v-icon',[_vm._v("mdi-menu")])],1):_vm._e(),_c('span',{ref:"mainTitle",staticClass:"hidden-sm-and-down"},[_vm._v(_vm._s(_vm.title))])],1),_c('v-spacer'),(_vm.user)?_c('p',{staticClass:"ma-2"},[_vm._v(_vm._s(_vm.user.name))]):_vm._e(),_c('v-btn',{attrs:{"icon":"","large":""}},[_c('v-avatar',{attrs:{"size":"32px","tile":""}},[_c('img',{attrs:{"src":require("@/assets/infordio.png"),"alt":"Infordio"}})])],1)],1),_c('v-main',{ref:"mainContainer",staticStyle:{"background-color":"#f5f5ff"}},[_c('router-view',{key:_vm.$route.fullPath}),_c('v-snackbar',{attrs:{"timeout":6000,"color":_vm.snackbar.color},scopedSlots:_vm._u([{key:"action",fn:function(ref){
      var attrs = ref.attrs;
return [_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.snackbar.showing = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.snackbar.showing),callback:function ($$v) {_vm.$set(_vm.snackbar, "showing", $$v)},expression:"snackbar.showing"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }