import Api from '@/services/ServiceBase'
const path = 'clients'

export default {
  add(client) {
    return Api().post(path, client)
  },
  list(name, page, discontinued, nif, email, phone,externalID) {
    return Api().get(path, {
      params: {
        name,
        page,
        discontinued,
        nif,
        email,
        phone,
        externalID
      }
    })
  },
  listDebounce(search) {
    return Api().get(path + `/debounce`, {
      params: {
        search
      }
    })
  },
  show(id) {
    return Api().get(path + `/${id}`)
  },  
  getClientObservations(clientId) {
    return Api().get(path + `/observation/list/${clientId}`)
  },
  // put(client) {
  //   return Api().put(path + `/${client.id}`, client)
  // },
  createUpdateObservation(observation) {
    return Api().post(path + `/observation/createUpdate`, observation)
  },
  delete(clientId) {
    return Api().get(path + '/delete' + `/${clientId}`)
  },
  deleteObservation(observationId) {
    return Api().get(path + '/delete' + `/observation/${observationId}`)
  }
}
