<template>
  <v-container>
    <v-speed-dial fab fixed v-model="fab" :bottom="true" :right="true" direction="top" :open-on-hover="false">
      <template v-slot:activator>
        <v-btn v-model="fab" color="blue darken-2" dark large fab>
          <v-icon v-if="fab">mdi-close</v-icon>
          <v-icon v-else>mdi-filter-variant</v-icon>
        </v-btn>
      </template>
      <v-btn fab dark large color="green" @click.stop="filterDialog = true">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <v-btn fab dark large color="indigo" @click="addEditItem()">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-speed-dial>

    <v-row align="center" justify="center">
      <v-col cols="12" md="10">
        <v-data-table class="mTable" id="mytable" v-infinite-scroll="getUsers" infinite-scroll-disabled="busy"
          infinite-scroll-distance="10" :headers="headers" :items="users" :loading="loading" hide-default-footer
          :items-per-page="users.length + 10">
          <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>

          <template v-slot:[`item.color`]="{ item }">
            <v-chip class="pa-5" :color="item.color" />
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <td class="justify-center layout px-0">

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" @click="addEditItem(item)">
                    <v-icon v-on="on" color="teal">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" @click="deleteItem(item)">
                    <v-icon v-on="on" color="pink">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Eliminar</span>
              </v-tooltip>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="editDialog" ref="test" persistent scrollable width="600" transition="dialog-bottom-transition">
      <add-edit-user ref="qwerty" :room="editedItem" @submited="submitedData" @close="close"></add-edit-user>
    </v-dialog>

    <v-dialog v-model="filterDialog" persistent max-width="600px">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Filtros :</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="filterDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field label="Nome" clearable v-model="userName"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="blue darken-1" text @click="Filter()">Procurar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import infiniteScroll from "vue-infinite-scroll";
import ServiceRooms from "@/services/ServiceRooms";
import AddEditUser from "@/components/room/AddEditRoom.vue";
import _ from "lodash";
export default {
  name: "List-Rooms",
  directives: {
    infiniteScroll
  },
  components: {
    AddEditUser
  },
  data() {
    return {
      editDialog: false,
      editedIndex: -1,
      editedItem: {
      },
      defaultItem: {
        color: '#FF00FF'
      },

      value: "",
      fileName: "",
      filterDialog: false,
      fab: false,
      page: 1,
      headers: [
        { text: "Nome", value: "name", sortable: false },
        { text: "Côr", value: "color", sortable: false },
        { text: "", value: "action", sortable: false, align: "center" }
      ],
      users: [],
      swatches: [],
      test: [],
      busy: false,
      loading: true,
      userName: null,
      login: null,
      externalID: null,
    };
  },
  methods: {
    async getUsers() {
      this.busy = true;
      this.loading = true;
      const response = await ServiceRooms.list(
        this.page
      );

      this.users = this.users.concat(response.data);

      this.page++;
      this.busy = false;
      if (response.data.length == 0) {
        this.busy = true;
      }
      this.loading = false;
    },
    async deleteItem(item) {
      if (confirm("Confirma a eliminação deste elemento?")) {
        try {
          const index = this.users.indexOf(item);
          await ServiceRooms.delete(item.id);
          this.users.splice(index, 1);
          this.$store.dispatch("setSnackbar", {});
        } catch (error) {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: error
          });
        }
      }
    },
    async addEditItem(item) {
      try {

        if (item) {
          this.editedIndex = this.users.indexOf(item);
          this.editedItem = Object.assign({}, item);
        } else {
          this.editedIndex = -1;
          this.editedItem = Object.assign({}, this.defaultItem);
        }

        this.editDialog = true;
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    submitedData(item) {
      if (this.editedIndex > -1) {
        Object.assign(this.users[this.editedIndex], item);
      } else {
        this.users.unshift(item);
      }

      for (let i = 0; i < this.swatches.length; i++) {

        const index = _.findIndex(this.swatches[i], function (o) {
          return o == item.color;
        });

        if (index != -1) {
          this.swatches[i].splice(index, 1);
          break;
        }
      }
    },
    Filter() {
      this.users = [];
      this.page = 1;
      this.filterDialog = false;
      this.getUsers();
    },
    close() {
      this.editDialog = false;
    },
  },
  async mounted() {
  },
  created() {
    const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {

      if (this.editDialog) {
        this.editDialog = false;
        next(false);
        return;
      }

      if (this.filterDialog) {
        this.filterDialog = false;
        next(false);
        return;
      }

      next();
    });

    this.$once('hook:destroyed', () => {
      unregisterRouterGuard()
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
/*bush fix the icon was pushed to the top*/
.btn--floating .icon {
  height: 50%;
}
</style>
