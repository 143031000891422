<template>
  <v-row class="fill-height">
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-btn fixed dark fab bottom right large color="indigo" @click.stop="$refs.eventDialog.open()">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-col>
      <v-sheet height="64">
        <v-toolbar flat>
          <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday">
            Hoje
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="prev">
            <v-icon small>
              mdi-chevron-left
            </v-icon>
          </v-btn>
          <v-btn fab text small color="grey darken-2" @click="next">
            <v-icon small>
              mdi-chevron-right
            </v-icon>
          </v-btn>
          <v-toolbar-title v-if="$refs.calendar">
            {{ $refs.calendar.title }}
          </v-toolbar-title>


          <v-icon color="primary" @click="modal = true">mdi-calendar</v-icon>

          <v-dialog ref="dialog" v-model="modal" persistent width="290px">
            <v-date-picker v-model="auxFocus" scrollable>
              <v-btn text color="error" @click="modal = false">
                Cancelar
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn text color="success" @click="saveDate()">
                Confirmar
              </v-btn>
            </v-date-picker>
          </v-dialog>

          <v-spacer></v-spacer>
          <v-menu>
            <template v-slot:activator="{ attrs, on }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on" outlined>
                Legenda
                <v-icon right dark>
                  mdi-home
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-for="(item, index) in rooms" :key="index">
                <v-list-item-avatar>
                  <v-chip class="pa-4 rounded-circle" :color="item.color">
                  </v-chip>
                </v-list-item-avatar>
                <v-list-item-title>{{ item.name }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar>
      </v-sheet>
      <v-sheet class="calHeight">
        <v-calendar ref="calendar" interval-width="60" locale="pt-PT" v-model="focus" color="primary" type="category"
          category-text="name" category-show-all :categories="users" :events="events"
          :first-time=$config.Calendar_First_Time :interval-count=$config.Calendar_Interval_Count
          :interval-minutes=$config.Calendar_Interval_Minutes @change="getCalendar" @click:event="showEvent"></v-calendar>
      </v-sheet>
    </v-col>
    <event-dialog ref="eventDialog" :rooms="rooms" :users="users" @handleEvent="handleEvent"
      @handleDelete="handleDelete"></event-dialog>
  </v-row>
</template>

<script>
import ServiceCalendars from "@/services/ServiceCalendars";
import EventDialog from "@/components/common/EventDialog.vue";
import _ from "lodash";
import { getUsersAndRooms } from "@/components/Utils";

export default {
  name: "Home",
  components: {
    EventDialog
  },
  data() {
    return {
      loading: false,
      focus: '',
      auxFocus: '',
      events: [],
      users: [],
      rooms: [],

      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substring(0, 10),
      modal: false,
    };
  },
  methods: {
    saveDate() {
      this.focus = this.auxFocus;
      this.modal = false;
    },
    setToday() {
      this.focus = ''
    },
    prev() {
      this.$refs.calendar.prev()
    },
    next() {
      this.$refs.calendar.next()
    },
    showEvent({ nativeEvent, event }) {
      this.$refs.eventDialog.open(event);

      nativeEvent.stopPropagation();
    },
    handleDelete(id) {

      let mIds = [];
      mIds = mIds.concat(id);

      mIds.forEach(id => {
        let index = -1;
        index = _.findIndex(this.events, { id });
        if (index != -1) this.events.splice(index, 1);
      });
    },
    handleEvent(events) {

      let mEvents = [];
      mEvents = mEvents.concat(events);
      mEvents.forEach(event => {
        event.category = (_.find(this.users, { 'id': event.UserId })).name;
        event.color = event.roomColor;

        const index = _.findIndex(this.events, { 'id': event.id });

        if (index === -1) {
          this.events.push(event);
        } else {
          this.events.splice(index, 1, event);
        }
      });
    },
    async getCalendar() {
      try {

        if (this.users.length == 0) {
          return;
        }

        this.loading = true;
        this.events = [];
        // var date = new Date(this.$refs.calendar.rt.date);

        // let startDate = date.toISOString().slice(0, 10);
        // date.setDate(date.getDate() + 1);
        // let endDate = date.toISOString().slice(0, 10);

        const response = await ServiceCalendars.getCalendar(
          null,
          this.$refs.calendar.lastStart.date,
          this.$refs.calendar.lastEnd.date,
        );

        _.forEach(response.data, value => {
          value.category = (_.find(this.users, { 'id': value.UserId })).name;
          value.color = value.roomColor;
          this.events.push(value);
        });

        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
  },
  async mounted() {
    try {
      this.loading = true;
      await getUsersAndRooms(1, 1, this.users, this.rooms);

      this.getCalendar();
    } catch (error) {
      this.$store.dispatch("setSnackbar", {
        color: "error",
        text: error
      });

    }
    this.loading = false;
  },
  computed: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
